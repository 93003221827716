import styled from 'styled-components';

import { DEFAULT } from 'colors';

export const ModalBodySignUp = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px;
  > img {
    width: 98px;
    position: absolute;
    left: 150px;
  }
  > h5 {
    font-weight: 600;
    font-size: 14px;
    text-align: center;
    color: #252F40;
    opacity: 0.6;
  }
  
  > div > input.empty:focus {
    outline: 0;
    border-color: #E293D3;
    box-shadow: 0px 0px 0px 2px rgba(226, 147, 211, 0.499809);
  }
  button.close {
    padding: 16px 1r16pxem;
    margin: -16px -16px -16px auto;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    float: right;
    font-size: 21px;
    font-weight: 600;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
  }
  button.close > span {
    cursor: pointer;
  }
`;

export const OptionsDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 30px;
  align-items: center;
  > div {
    width: 319px;
    display: grid;
    grid-template-columns: 54px 1fr;
    cursor: pointer;
  }
`;

export const OptionsPasswordDiv = styled.div`
  background: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin-top: 23px;
  margin-bottom: 23px;
  > img {
    padding: 15px 12px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  > span {
    padding: 18px 23px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: left;
    align-self: center;
    font-weight: 500;
    font-size: 16px;
    color: ${DEFAULT};
  }
`;

export const OptionsGmailDiv = styled.div`
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  > img {
    background: white;
    padding: 11px 10px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  > span {
    background: #0075FF;
    padding: 18px 23px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: left;
    align-self: center;
    font-weight: 500;
    font-size: 16px;
    color: white;
  }
`;

export const FeaturesDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`;

export const FormDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const FeatureDiv = styled.div`
  display: grid;
  grid-template-columns: 45px 1fr;
  column-gap: 15px;
  margin-bottom: 12px;
  > img {
    width: 45px;
  }
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  > div > span {
    color: #252F40;
  }
  > div > span:nth-child(1) {
    font-weight: 600;
    font-size: 14px;
  }
  > div > span:nth-child(2) {
    font-weight: normal;
    font-size: 12px;
    opacity: 0.5;
  }
`;


export const InputDiv = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  > input {
    height: 32px;
    padding: 4px 12px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    overflow: visible;
    margin: 5px 0px 10px 0px;
    :focus {
      outline: 0;
      border-color: #35C801;
      box-shadow: 0px 0px 0px 2px rgba(53, 200, 1, 0.5);
    }
  }
  > img {
    position: absolute;
    right: 10px;
    top: 20px;
    width: 18px;
  }
`;

export const InputModal = styled.input`
  display: block;
  height: 32px;
  padding: 4px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #D9D9D9;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: visible;
  margin: 5px 0px 10px 0px;
  :focus {
    outline: 0;
    border-color: #35C801;
    box-shadow: 0px 0px 0px 2px rgba(53, 200, 1, 0.5);
  }
`;

export const SpanModal = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 4px;
  span {
    margin-top: 5px;
    margin-bottom: 9px;
    font-size: 14px;
    color: #252F40;
  }
`;

export const LoginDiv = styled.div`
  display: flex;
  align-self: center;
  margin-top: 10px;
  span {
    font-size: 14px;
    color: #252F40;
  }
`;

export const ButtonSignUp = styled.button`
  background: linear-gradient(129.33deg, ${DEFAULT} 1.73%, #7933FE 99.85%);
  color: white;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
  box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  height: 40px;
  border-width: 0px;
  line-height: 16px;
  &:hover {
    background: linear-gradient(129.33deg, #0D0528 1.73%, #5913DE 99.85%);
  }
`;

export const ButtonLogIn = styled.button`
  background: transparent;
  color: #0066FF;
  cursor: pointer;
  border-width: 0px;
  font-size: 14px;
  font-weight: normal;
  text-decoration-line: underline;
  &:hover {
    color: #0066DD;
  }
`;


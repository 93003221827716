import type { HTTPFunctions } from '../types';
import type { CustomizedTarget, CustomizedTargetWithId } from 'pages/Customize/types';

const targets = ({ get, post, put, del }: HTTPFunctions) => ({
  getTargets: () => get('targets/'),
  addTarget: (target: CustomizedTarget) => post('targets/', {...target}),
  updateTarget: (target: CustomizedTargetWithId) => put(`targets/${target.id}`, {...target}),
  delTarget: (id: number) => del(`targets/${id}`),
  getTargetUsersToShare: (id: number) => get(`targets/share/${id}`),
  setTargetUsersToShare: (id: number, data: any) => put(`targets/share/${id}`, data)
});

export default targets;
import auth from './auth';
import type { LocalStorageFunctions, LocalPreferences } from './types';

const USER_PREFERENCES: string = 'tigim-user-preferences';

const getAllPreferences = (fn: LocalStorageFunctions) => () => {
  const user = auth(fn).getUser();
  const obj = fn.readObject(USER_PREFERENCES);
  if (obj && user && obj[user.user.email]) {
    return obj[user.user.email];
  }
  return {};
};

const addOne = (fn: LocalStorageFunctions) => (key: string, value: any) => {
  const user = auth(fn).getUser();
  let obj = fn.readObject(USER_PREFERENCES);
  if (!obj) {
    obj = {};
  }
  if (obj && user) {
    if (!obj[user.user.email]) {
      obj[user.user.email] = {};
    }
    obj[user.user.email][key] = value;
    fn.writeObject(USER_PREFERENCES, obj);
  }
};

const getOne = (fn: LocalStorageFunctions) => (key: string) => {
  const obj = getAllPreferences(fn)();
  return obj[key];
};

const preferences = (fn: LocalStorageFunctions): LocalPreferences => ({
  addOne: addOne(fn),
  getOne: getOne(fn),
  SUGGESTION_FLAG: 'SUGGESTION_FLAG'
});

export default preferences;

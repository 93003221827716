import { useEffect, useRef, useCallback } from 'react'

const useMounted = () => {
  const mountedRef = useRef(false);
  const isMounted = useCallback(() => mountedRef.current, []);

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    }
  }, []);

  return isMounted;
}

export default useMounted;

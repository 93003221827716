import { createSlice } from '@reduxjs/toolkit';

interface State {
  showMenu: boolean;
  editMode: boolean;
}

const initialState: State = {
  showMenu: true,
  editMode: false
};

export const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    show: (state: State) => {
      state.showMenu = true;
    },
    hide: (state: State) => {
      state.showMenu = false;
    },
    toggleEditMode: (state: State) => {
      state.editMode = !state.editMode;
    }
  },
});

export const { show, hide, toggleEditMode } = slice.actions;

export default slice.reducer;

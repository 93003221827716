import React from 'react';

import MenuDesktop from 'components/atoms/MenuDesktop';
import { HeaderTag } from './elements';

interface HeaderProps {
  isOpenLeftMenu: boolean;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  zIndex: string;
  showAvailable?: boolean;
}

const Header = ({isOpenLeftMenu, isOpen, setIsOpen, zIndex, showAvailable=true}: HeaderProps) => (
  <HeaderTag $zIndex={zIndex} $isOpen={isOpenLeftMenu}>
    <MenuDesktop isOpen={isOpen} setIsOpen={setIsOpen} showAvailable={showAvailable} />
  </HeaderTag>
);

export default Header;

import type { NewProduct, Product } from 'pages/Admin/Products/types';
import type { HTTPFunctions } from './types';
import type { Billing } from 'pages/Payments/BillingOwner/types';
import type { NewPlan, Plan } from 'pages/Admin/Plans/types';

const payments = ({ get, post, put, del }: HTTPFunctions) => ({
  listProducts: () => get('/products/'),
  getProductById: (id: string) => get(`/products/${id}`),
  addProduct: (data: NewProduct) => post('/products/', data),
  updateProduct: (data: Product) => put(`/products/${data.id}`, data),
  delProductById: (id: number) => del(`/products/${id}`),
  listPlans: () => get('/plans/'),
  getPlanById: (id: string) => get(`/plans/${id}`),
  addPlan: (data: NewPlan) => post('/plans/', data),
  updatePlan: (data: Plan) => put(`/plans/${data.id}`, data),
  delPlanById: (id: number) => del(`/plans/${id}`),
  getPaymentsSummary: () => get('/summary/'),
  createCheckoutSession: () => post('/create-checkout-session', {}),
  createInfoCheckoutSession: () => post('/info/create-checkout-session', {}),
  reactivateSubscription: () => put('/subscriptions/reactivate', {}),
  getSubscription: () => get('/subscriptions/'),
  cancelSubscription: () => del('/subscriptions/'),
  getCurrentCredits: () => get('/subscriptions/credits'),
  getBilling: () => get('/billing/'),
  addBilling: (data: Billing) => post('/billing/', data),
  updateBilling: (data: Billing) => put('/billing/', data),
  getInvoices: () => get('/invoices/'),
  getInvoicesLastest: () => get('/invoices/lastest'),
});

export default payments;

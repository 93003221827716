import styled from 'styled-components';

import { PAGE_BACKGROUND } from 'colors';

type HeaderTagProps = {
  $zIndex: string;
  $isOpen: boolean;
}

export const HeaderTag = styled.header<HeaderTagProps>`
  grid-column: 2 / -1;
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: ${({ $zIndex }) => $zIndex};
  margin-left: ${({$isOpen}) => $isOpen? '245px': '100px'};
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  background-color: ${PAGE_BACKGROUND};
  height: 80px;
  @media print {
    display: none;
  }
`;

import React from 'react';

import ErrorThrower from 'ErrorThrower';
import useMovable from 'hooks/useMovable';
import {
  MainDiv,
  BackDropDiv,
  ModalSm,
  ModalMd,
  ModalContent,
  ModalHeaderDiv,
  ModalBodyDiv,
  ModalFooterDiv
} from './elements';

export type ModalSize = 'md' | 'sm' | undefined;

export interface Size {
  width: string;
  height: string;
}

interface ModalProps {
  children: string | React.ReactNode;
  onClose(): void;
  size?: ModalSize;
}

interface ModalGenericProps {
  children: string | React.ReactNode;
  onClose(): void;
  ModalDiv: any;
  id?: string;
  className?: string;
  movable?: boolean;
}

type ModalGenericWithSizeProps = ModalGenericProps & {
  size: Size;
}

interface ModalHeaderProps {
  children: string | React.ReactNode;
  onClose(): void;
}

interface ModalBodyProps {
  children: string | React.ReactNode;
}

interface ModalFooterProps {
  children: string | React.ReactNode;
}

const getModalBySize = (size: ModalSize) => {
  if (size === 'sm') return ModalSm;
  return ModalMd;
}

export const Modal = ({ children, onClose, size }: ModalProps) => {
  const Content = getModalBySize(size)
  return (
    <MainDiv>
      <Content>
        <ModalContent>
          {children}
        </ModalContent>
      </Content>
      <BackDropDiv onClick={onClose}/>
    </MainDiv>
  );
};

export const ModalGeneric = ({ children, onClose, ModalDiv, id, className="", movable=false }: ModalGenericProps) => {
  const props = id ? { id } : {};
  const {
    ref,
    onMouseLeave,
    onMouseMove,
    onMouseDown,
    onMouseUp
  } = useMovable(movable);

  return (
    <ErrorThrower componentName="ModalGeneric">
      <MainDiv>
        <ModalDiv
          {...props}
          className={className}
          ref={ref}
          onMouseLeave={onMouseLeave}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}>
          <ModalContent>
            {children}
          </ModalContent>
        </ModalDiv>
        <BackDropDiv onClick={onClose}/>
      </MainDiv>
    </ErrorThrower>
  );
};

export const ModalGenericWithSize = ({ children, onClose, ModalDiv, size }: ModalGenericWithSizeProps) => {
  return (
    <ErrorThrower componentName="ModalGeneric">
      <MainDiv>
        <ModalDiv {...size}>
          <ModalContent>
            {children}
          </ModalContent>
        </ModalDiv>
        <BackDropDiv onClick={onClose}/>
      </MainDiv>
    </ErrorThrower>
  );
};

export const ModalHeader = ({ children, onClose }: ModalHeaderProps) => (
  <ModalHeaderDiv onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}>
    {children}
    <button type="button" aria-label="Close" onClick={onClose}>
      <span aria-hidden="true">×</span>
    </button>
  </ModalHeaderDiv>
);

export const ModalBody = ({ children }: ModalBodyProps) => (
  <ModalBodyDiv onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}>
    {children}
  </ModalBodyDiv>
);

export const ModalFooter = ({ children }: ModalFooterProps) => (
  <ModalFooterDiv onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}>
    {children}
  </ModalFooterDiv>
);


import type { WordExplorerDataResponse } from 'pages/WordExplorer/types';
import type { HTTPFunctions } from '../types';

const publics = ({ get, post }: HTTPFunctions) => ({
  publicWordExplorer: (word: string): Promise<WordExplorerDataResponse> =>
    get(`/words/public/word-explorer/${word}?request_types=definitions&request_types=inflections&request_types=synonyms&request_types=cefr&request_types=level&request_types=synonyms_lexical`),
  publicReadabilityChecker: (text: string) => post(`/readability/public/`, { text }),
  publicBrainPain: (text: string) => post(`/brain-pain/public/`, { text }),
  publicSentimentAnalysis: (text: string) => post(`/sentiment/public/`, { text })
});

export default publics;
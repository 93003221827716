import { createSlice } from '@reduxjs/toolkit';

interface State {
  isLoading: boolean;
  message: string;
}

interface Action {
  payload: string;
}

const initialState: State = {
  isLoading: false,
  message: ''
};

export const slice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    open: (state: State, action: Action) => {
      state.message = action.payload;
      state.isLoading = true;
    },
    close: (state: State) => {
      state.isLoading = false;
      state.message = '';
    }
  },
});

export const { open, close } = slice.actions;

export default slice.reducer;

import styled from 'styled-components';

export const MainDiv = styled.div`
  display: block;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  transition: opacity 0.15s linear;
  z-index: 5;
`;

export const BackDropDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  transition: opacity 0.15s linear;
`;

export const ModalDiv = styled.div`
  transform: none;
  transition: transform 0.25s ease-out;
  min-height: calc(100% - 56px);
  margin: 28px auto;
  z-index: 3;
  display: flex;
  align-items: center;
  position: relative;
  width: auto;
  pointer-events: none;
`;

export const ModalMd = styled(ModalDiv)`
  max-width: 600px;
`;

export const ModalSm = styled(ModalDiv)`
  max-width: 500px;
`;

export const ModalContent = styled.div`
  position: relative;
  overflow: show;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 12px;
  outline: 0;
  color: #495057;
`;

export const ModalHeaderDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 16px;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  > h5 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 1.5;
  }
  > button {
    padding: 16px 16px;
    margin: -16px -16px -16px auto;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    float: right;
    font-size: 21px;
    font-weight: 600;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
  }
  > button > span {
    cursor: pointer;
  }
`;

export const ModalBodyDiv = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 16px;
`;

export const ModalFooterDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 12px;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  > * {
    margin: 4px;
  }
`;

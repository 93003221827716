import { createSlice } from '@reduxjs/toolkit';

interface State {
  name: string;
}

interface Action {
  payload: string;
}

const initialState: State = {
  name: ''
};

export const slice = createSlice({
  name: 'name',
  initialState,
  reducers: {
    setName: (state: State, action: Action) => {
      state.name = action.payload;
    }
  },
});

export const { setName } = slice.actions;

export default slice.reducer;

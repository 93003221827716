import { configureStore } from '@reduxjs/toolkit';
import { reducer as toastr } from 'react-redux-toastr';

import analysisReport from './reducers/analysisReport';
import auth from './reducers/auth';
import contactLogin from './reducers/contactLogin';
import contactUs from './reducers/contactUs';
import loading from './reducers/loading';
import menu from './reducers/menu';
import name from './reducers/name';
import signIn from './reducers/signIn';
import signUp from './reducers/signUp';
import usage from './reducers/usage';

export default configureStore({
  reducer: {
    toastr,
    analysisReport,
    auth,
    contactLogin,
    contactUs,
    loading,
    menu,
    name,
    signIn,
    signUp,
    usage
  }
});

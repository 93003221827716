import type { HTTPFunctions } from "../types";

const words = ({ get, post, del }: HTTPFunctions) => ({
  wordSearch: (
    id: string,
    word: string,
    synonyms: boolean,
    antonyms: boolean
  ) => {
    const params1 = synonyms ? "&request_types=synonyms" : "";
    const params2 = antonyms ? "&request_types=antonyms" : "";
    return get(
      `/words/define/${id}/${word}?request_types=lemma&request_types=inflections${params1}${params2}`
    );
  },
  wordSearchMultiple: (
    id: string,
    wordsArray: string[],
    synonyms: boolean,
    antonyms: boolean
  ) => {
    const params1 = synonyms ? "&request_types=synonyms" : "";
    const params2 = antonyms ? "&request_types=antonyms" : "";
    return post(
      `/words/define-multiple/${id}?request_types=lemma&request_types=inflections${params1}${params2}`,
      wordsArray
    );
  },
  difficultWordSearch: (id: string) =>
    get(
      `/words/define/difficult-word/${id}?request_types=definitions&request_types=synonyms_lexical`
    ),
  wordExplorer: (word: string) =>
    get(
      `/words/word-explorer/${word}?request_types=definitions&request_types=inflections&request_types=synonyms&request_types=cefr&request_types=level&request_types=synonyms_lexical`
    ),
  addAlternativeWord: (word: string, alternativeWord: string) =>
    post("/alternative-words/", { word, alternative: alternativeWord }),
  delAlternativeWord: (word: string, alternativeWord: string) =>
    del(`/alternative-words/${word}/${alternativeWord}`),
  getAlternativeWords: (wordsArray: string[]) =>
    post("/alternative-words/company-alternatives", wordsArray),
});

export default words;

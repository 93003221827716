import styled from 'styled-components';

import { DEFAULT } from 'colors';

export const ButtonTutorial = styled.button`
  background-color: white;
  color: ${DEFAULT};
  border: none;
  border-radius: 8px;
  padding: 14px 35px;
  font-size: 14px;
  font-weight: 600;
  align-self: center;
  margin-top: 10px;
  cursor: pointer;
`;

export const SVGTutorialCopyIcon = styled.svg`
  background: transparent;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
`;

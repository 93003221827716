import React, { useEffect, useState } from 'react';

import useMounted from 'hooks/useMounted';
import level1 from './levels/level1.svg';
import level2 from './levels/level2.svg';
import level3 from './levels/level3.svg';
import level4 from './levels/level4.svg';
import level5 from './levels/level5.svg';
import level6 from './levels/level6.svg';
import ErrorThrower from 'ErrorThrower';

type FullscreenLoaderSVGProps = {
  text: string;
  show: boolean;
}

const now = () => Math.round(new Date().getTime() / 4000);
const getEquivalentLevel = (level: number) =>
  level - (6 * Math.floor(level / 6));

const FullscreenLoaderSVG = ({ text, show}: FullscreenLoaderSVGProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const [timer, setTimer] = useState<any>();
  const [initialCounter, setInitialCounter] = useState<number>(1);
  const [level, setLevel] = useState<number>(0);
  const isMounted = useMounted();

  useEffect(() => {
    if (isMounted() && !loaded) {
      setLoaded(true);
      clearInterval(timer);
      setTimer(setInterval(() => {
        if (isMounted()) {
          const val = now() - initialCounter
          if (val > 5) {
            setLevel(getEquivalentLevel(val));
          } else {
            setLevel(val);
          }
        }
      }, 4000));
    }
  }, [isMounted, timer, setLevel, initialCounter, loaded]);

  useEffect(() => {
    if (isMounted() && show) {
      setLevel(0);
      setInitialCounter(now());
      setLoaded(false);
    }
  }, [isMounted, show])
  if (!show) return null;
  return (
    <ErrorThrower componentName='FullscreenLoaderSVG'>
      <div>
        <img src={getImage(1)} alt="spinner" className={level === 0? 'show': 'hide'} />
        <img src={getImage(2)} alt="spinner" className={level === 1? 'show': 'hide'} />
        <img src={getImage(3)} alt="spinner" className={level === 2? 'show': 'hide'} />
        <img src={getImage(4)} alt="spinner" className={level === 3? 'show': 'hide'} />
        <img src={getImage(5)} alt="spinner" className={level === 4? 'show': 'hide'} />
        <img src={getImage(6)} alt="spinner" className={level === 5? 'show': 'hide'} />
      </div>
      { text && <h3>{text}</h3> }
    </ErrorThrower>
  );
}

export default FullscreenLoaderSVG;

const getImage = (level: number) => {
  if (level === 1) return level1;
  if (level === 2) return level2;
  if (level === 3) return level3;
  if (level === 4) return level4;
  if (level === 5) return level5;
  return level6;
};

import { Tutorials } from './tutorialsTypes';
import editing from './tutorialBoxes/editing';
import sharing from './tutorialBoxes/sharing';
import exporting from './tutorialBoxes/exporting';
import addingText from './tutorialBoxes/addingText';
import organising from './tutorialBoxes/organising';
import addComments from './tutorialBoxes/addComments';
import bulkActions from './tutorialBoxes/bulkActions';
import guideVideos from './tutorialBoxes/guideVideos';
import resultsPage from './tutorialBoxes/resultsPage';
import exploreWords from './tutorialBoxes/exploreWords';
import filteringGroups from './tutorialBoxes/filteringGroups';

export const TUTORIAL_ADDING_TEXTS = 'adding-texts';
export const TUTORIAL_FILTERING_GROUPS = 'filtering-groups';
export const TUTORIAL_EXPORTING = 'exporting';
export const TUTORIAL_ORGANISING = 'organising';
export const TUTORIAL_SHARING = 'sharing';
export const TUTORIAL_BULK_ACTIONS = 'bulk-actions';
export const TUTORIAL_EDITING = 'editing';
export const TUTORIAL_ADD_COMMENTS = 'add-comments';
export const TUTORIAL_RESULTS_PAGE = 'results-page';
export const TUTORIAL_EXPLORE_WORDS = 'explore-words';
export const TUTORIAL_GUIDE_VIDEOS = 'guide-videos';

export const TUTORIAL_ARTICLE_NEW_DOCUMENT = `The amount of alcohol young people drink in many high-income countries has seen a marked decline since the early 2000s. But in many developing countries, the opposite is happening. In this episode of The Conversation Weekly podcast, we talk to three experts studying trends in young people’s drinking habits to find out why and we explore the questions this raises about the way young people see themselves and their place in the world.`;

const TUTORIALS: Tutorials = {
  [TUTORIAL_ADDING_TEXTS]: addingText,
  [TUTORIAL_FILTERING_GROUPS]: filteringGroups,
  [TUTORIAL_EXPORTING]: exporting,
  [TUTORIAL_ORGANISING]: organising,
  [TUTORIAL_SHARING]: sharing,
  [TUTORIAL_BULK_ACTIONS]: bulkActions,
  [TUTORIAL_EDITING]: editing,
  [TUTORIAL_ADD_COMMENTS]: addComments,
  [TUTORIAL_RESULTS_PAGE]: resultsPage,
  [TUTORIAL_EXPLORE_WORDS]: exploreWords,
  [TUTORIAL_GUIDE_VIDEOS]: guideVideos,
};

export default TUTORIALS;

export interface Action {
  name: string;
  done: boolean;
  tutorialId: string;
}

export interface Tutorial {
  title: string;
  actions: Action[];
}

export const tutorialMenuItems: Tutorial[] = [
  {
    title: '1. Analyse your texts',
    actions: [
      {name: 'Adding texts', done: false, tutorialId: TUTORIAL_ADDING_TEXTS}
    ]
  }, {
    title: '2. Your results',
    actions: [
      {name: 'Filtering categories', done: false, tutorialId: TUTORIAL_FILTERING_GROUPS},
      {name: 'Exporting', done: false, tutorialId: TUTORIAL_EXPORTING},
    ]
  }, {
    title: '3. Your documents',
    actions: [
      {name: 'Organising', done: false, tutorialId: TUTORIAL_ORGANISING},
      {name: 'Sharing', done: false, tutorialId: TUTORIAL_SHARING},
      {name: 'Bulk actions', done: false, tutorialId: TUTORIAL_BULK_ACTIONS},
    ]
  }, {
    title: '4. Edit your docs',
    actions: [
      {name: 'Editing', done: false, tutorialId: TUTORIAL_EDITING},
      {name: 'Adding comments', done: false, tutorialId: TUTORIAL_ADD_COMMENTS},
    ]
  }, {
    title: '5. How to customise',
    actions: [
      {name: 'Summary page', done: false, tutorialId: TUTORIAL_RESULTS_PAGE},
    ]
  }, {
    title: '6. Explorer',
    actions: [
      {name: 'Everyday Words', done: false, tutorialId: TUTORIAL_EXPLORE_WORDS},
    ]
  }, {
    title: '7. Learn more',
    actions: [
      {name: 'Guide videos', done: false, tutorialId: TUTORIAL_GUIDE_VIDEOS},
    ]
  }
];
import { MutableRefObject, useRef } from 'react';

const useFocus = (): [MutableRefObject<any>, () => void] => {
  const htmlElRef = useRef(null);
  const setFocus = (): void => {
    if (htmlElRef.current) {
      const obj: HTMLElement = htmlElRef.current;
      obj.focus();
    }
  };

  return [ htmlElRef, setFocus ];
}

export default useFocus;

import React from 'react';

import { FeatureDiv } from './elements';

interface FeatureProps {
  icon: string;
  alt?: string;
  children: React.ReactNode;
}

const Feature = ({icon, alt, children}: FeatureProps) => (
  <FeatureDiv>
    <img src={icon} alt={alt} />
    <div>{children}</div>
  </FeatureDiv>
);

export default Feature;

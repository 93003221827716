import type { HTTPFunctions } from '../types';

const comments = ({ get, post, put, del }: HTTPFunctions) => ({
  getComments: (documentId: string) => get(`comments/document/${documentId}`),
  updateComment: (id: number, comment: string) => put(`comments/${id}`, {comment}),
  delComment: (id: number) => del(`comments/${id}`),
  setCommentComplete: (id: number, resolved: boolean) => put(`comments/${id}`, {resolved}),
  addComment: (documentId: string, original: string, comment: string, position: number) => post('/comments/', { document_id: documentId, original, comment, position }),
  addReply: (id: number, reply: string) => post('/replies/', { reply, comment_id: id }),
  updateReply: (id: number, reply: string) => put(`replies/${id}`, {reply}),
  delReply: (id: number) => del(`replies/${id}`)
});

export default comments;
import type { LocalStorageFunctions, IntUserResponse } from './types';
import type { ChoiceAsDefault } from 'pages/NewDocument/types';

const CHOICE_AS_DEFAULT: string = 'choice-as-default';
const USER: string = 'tigim-user';

const getUser = (fn: LocalStorageFunctions) => (): IntUserResponse | undefined =>
  fn.readObject(USER);

const getLocalStorageChoiceAsDefault = (fn: LocalStorageFunctions) => (): ChoiceAsDefault | undefined => {
  const user = getUser(fn)();
  if (!user) return undefined;
  const choice = fn.readObject(CHOICE_AS_DEFAULT);
  if (!choice?.[user.user.email]) return undefined;
  return choice[user.user.email];
};

const setLocalStorageChoiceAsDefault = (fn: LocalStorageFunctions) => (data: ChoiceAsDefault) => {
  const user = getUser(fn)();
  if (!user) return undefined;
  let choice = fn.readObject(CHOICE_AS_DEFAULT);
  if (!choice) {
    choice = {};
  }
  choice[user.user.email] = data;
  fn.writeObject(CHOICE_AS_DEFAULT, choice);
};

const delLocalStorageChoiceAsDefault = (fn: LocalStorageFunctions) => () => {
  const user = getUser(fn)();
  if (!user) return undefined;
  const choice = fn.readObject(CHOICE_AS_DEFAULT);
  if (!choice?.[user.user.email]) return undefined;
  delete choice[user.user.email];
  fn.writeObject(CHOICE_AS_DEFAULT, choice);
};

const choiceAsDefault = (fn: LocalStorageFunctions) => ({
  getLocalStorageChoiceAsDefault: getLocalStorageChoiceAsDefault(fn),
  setLocalStorageChoiceAsDefault: setLocalStorageChoiceAsDefault(fn),
  delLocalStorageChoiceAsDefault: delLocalStorageChoiceAsDefault(fn)
});

export default choiceAsDefault;

import styled from 'styled-components';

import { TUTORIAL_BACKGROUND, TUTORIAL_COLOR, TUTORIAL_BORDER_COLOR } from 'colors';

const BaseDiv = styled.div`
  position: absolute;
  > div {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 6;
    background-color: ${TUTORIAL_BACKGROUND};
    color: ${TUTORIAL_COLOR};
    font-size: 16px;
    font-weight: 400;
    padding: 10px 40px 20px 10px;
    border-radius: 8px;
    border: 1px solid ${TUTORIAL_BORDER_COLOR};
    > p {
      margin-top: 5px;
      margin-bottom: 5px;
      width: max-content;
    }
    > button.close {
      position: absolute;
      top: 0;
      right: 0;
      padding: 5px 10px;
      margin: 0;
      border: none;
      background-color: transparent;
      color: white;
      cursor: pointer;
      font-size: 20px;
    }
    > div.steps {
      position: absolute;
      bottom: 10px;
      right: 10px;
      display: flex;
      flex-direction: row;
      align-self: flex-end;
      font-size: 11px;
    }
  }
`;

type DivProps = {
  $top: string;
  $bottom: string;
  $left: string;
  $right: string;
}

export const Div = styled(BaseDiv)<DivProps>`
  top: ${({ $top }) => $top};
  bottom: ${({ $bottom }) => $bottom};
  left: ${({ $left }) => $left};
  right: ${({ $right }) => $right};
`;

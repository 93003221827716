import { TutorialBox } from '../tutorialsTypes';

const resultsPage: TutorialBox[][] = [
  [{
    top: "60px",
    left: "400px",
    content: ['In your document summary', 'dashboard']
  }], [{
    top: "-110px",
    left: "300px",
    content: ['Click on a card and hold for a couple', 'of seconds to be able to move it']
  }], [{
    top: "-50px",
    left: "-300px",
    doNext: true,
    hasButtonNext: true,
    buttonNextText: 'Got it, thanks!',
    content: ['Reorganise your cards in the best way', 'for you and save']
  }]
];

export default resultsPage;

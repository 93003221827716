import type { Size } from 'pages/Ux/types';
import type { LocalStorageFunctions, IntUserResponse, LocalTouchPointZoom } from './types';

const USER: string = 'tigim-user';
const TOUCHPOINT_ZOOM: string = 'tigim-touchpoint-zoom';

const getUser = (fn: LocalStorageFunctions) => (): IntUserResponse | undefined =>
  fn.readObject(USER);

const setTouchPointZoom = (fn: LocalStorageFunctions) => (rootId: string, size: Size) => {
  const user = getUser(fn)();
  if (!user) return undefined;
  const data = fn.readObject(TOUCHPOINT_ZOOM);
  if (!data) {
    fn.writeObject(TOUCHPOINT_ZOOM, { [user.user.email]: { [rootId]: size } });
  } else if (!data[user.user.email]) {
    fn.writeObject(TOUCHPOINT_ZOOM, { ...data, [user.user.email]: { [rootId]: size } });
  } else {
    fn.writeObject(TOUCHPOINT_ZOOM, {
      ...data, [user.user.email]: { ...data[user.user.email], [rootId]: size },
    });
  }
};

const getTouchPointZoom = (fn: LocalStorageFunctions) => (rootId: string): Size | null => {
  const user = getUser(fn)();
  if (!user) return null;
  const data = fn.readObject(TOUCHPOINT_ZOOM);
  if (!data?.[user.user.email]?.[rootId]) return null;
  return data[user.user.email][rootId];
};

const touchPointsZoom = (fn: LocalStorageFunctions): LocalTouchPointZoom => ({
  setTouchPointZoom: setTouchPointZoom(fn),
  getTouchPointZoom: getTouchPointZoom(fn)
});

export default touchPointsZoom;

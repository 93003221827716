import type { LocalStorageFunctions, IntUserResponse, LocalTutorials } from './types';

const USER: string = 'tigim-user';
const TUTORIALS: string = 'tigim-tutorials';

const getUser = (fn: LocalStorageFunctions) => (): IntUserResponse | undefined =>
  fn.readObject(USER);

const setTutorialIsDone = (fn: LocalStorageFunctions) => (tutorial: string) => {
  const user = getUser(fn)();
  if (!user) return undefined;
  const data = fn.readObject(TUTORIALS);
  if (!data) {
    fn.writeObject(TUTORIALS, { [user.user.email]: [tutorial] });
  } else if (!data[user.user.email]) {
    fn.writeObject(TUTORIALS, { ...data, [user.user.email]: [tutorial] });
  } else if (!data[user.user.email].includes(tutorial)) {
    fn.writeObject(TUTORIALS, {
      ...data,
      [user.user.email]: [...data[user.user.email], tutorial],
    });
  }
};

const getTutorials = (fn: LocalStorageFunctions) => (): string[] => {
  const user = getUser(fn)();
  if (!user) return [];
  const data = fn.readObject(TUTORIALS);
  if (!data?.[user.user.email]) return [];
  return data[user.user.email];
};

const clearTutorials = (fn: LocalStorageFunctions) => () => {
  const user = getUser(fn)();
  if (!user) return;
  const data = fn.readObject(TUTORIALS);
  if (!data?.[user.user.email]) return;
  delete data[user.user.email];
  fn.writeObject(TUTORIALS, data);
};

const tutorials = (fn: LocalStorageFunctions): LocalTutorials => ({
  setTutorialIsDone: setTutorialIsDone(fn),
  getTutorials: getTutorials(fn),
  clearTutorials: clearTutorials(fn),
});

export default tutorials;

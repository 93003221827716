import styled from 'styled-components';

export const Div = styled.div`
  width: 100%;
  height: 100%;
  z-index: 20;
  position: fixed;
  top:0; right:0; bottom:0; left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  h3 {
    font-family: Helvetica, sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin: 0;
    text-align: center;
  }
`;

export const MainDiv = styled.div`
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  font-family: sans-serif;
  text-align: center;
  line-height: 1;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  max-width: 50%;
  max-height: 50%;
  min-width: 200px;
  min-height: 126px;
  padding: 20px 40px;
  > div {
    min-height: 106px;
    > img {
      width: 50%;
      margin: 0 auto;
      -webkit-animation: rotating 2s linear infinite;
      -moz-animation: rotating 2s linear infinite;
      -ms-animation: rotating 2s linear infinite;
      -o-animation: rotating 2s linear infinite;
      animation: rotating 2s linear infinite;
    }
    > img.hide {
      display: none;
    }
  }
  @-webkit-keyframes rotating {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

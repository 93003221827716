import type { HTTPFunctions } from '../types';

import comments from './comments';
import analyze from './analyze';
import folder from './folder';
import share from './share';
import words from './words';
import targets from './targets';
import publics from './publics';
import profiles from './profiles';
import touchpoints from './touchpoints';

const analysis = (props: HTTPFunctions) => ({
  getUsage: () => props.get('/usage/'),
  ...analyze(props),
  ...comments(props),
  ...folder(props),
  ...share(props),
  ...words(props),
  ...targets(props),
  ...publics(props),
  ...profiles(props),
  ...touchpoints(props)
});

export default analysis;

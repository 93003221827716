import React, { useState } from 'react';

import {
  ModalBodySignIn,
  InputModal,
  FormDiv,
  LoginDiv,
  ButtonSignIn,
  ButtonLogIn
} from './elements';
import ModalSmall from './ModalSmall';
import { ModalForgotProps } from './types';
import useFocus from 'hooks/useFocus';
import validateEmail from 'functions/general/validateEmail';
import tigimIcon from 'images/tigim.svg';

const ModalForgot= ({onClose, onSend, onReturn}: ModalForgotProps) => {
  const [email, setEmail] = useState<string>('');
  const [emailRef, setEmailFocus] = useFocus();

  const handleSend = () => {
    if (!validateEmail(email)) return setEmailFocus();
    onSend(email);
    onClose();
  };
  const onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };
  return (
    <ModalSmall onClose={onClose}>
      <ModalBodySignIn onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}>
        <button
          className="close"
          type="button"
          aria-label="Close"
          onClick={onClose}>
          <span aria-hidden="true">×</span>
        </button>
        <img src={tigimIcon} alt="tigim" />
        <h5>Password Recovery</h5>
        <FormDiv>
          <InputModal
            ref={emailRef}
            id="email"
            name="email"
            placeholder="Email"
            className={validateEmail(email)? '': 'empty'}
            value={email}
            onKeyPress={onKeyPress}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}/>
          <ButtonSignIn
            className={validateEmail(email)? 'active': 'inactive'}
            onClick={handleSend}>
            Recover password
          </ButtonSignIn>
          <LoginDiv>
            <ButtonLogIn onClick={onReturn}>
              Return to Login
            </ButtonLogIn>
          </LoginDiv>
        </FormDiv>
      </ModalBodySignIn>
    </ModalSmall>
  );
};

export default ModalForgot;

import { googleLogout } from '@react-oauth/google';

import { useMainContext } from 'routes/MainContextProvider';
import { logout } from 'store/reducers/auth';

const useOnLogout = () => {
  const { dispatch, history } = useMainContext();

  const onLogoutSuccess = () => {
    dispatch(logout());
    history('/analysis');
  };
  return () => {
    googleLogout();
    onLogoutSuccess();
  };
};

export default useOnLogout;

import React from 'react';

import { useMainContext } from 'routes/MainContextProvider';
import { close as setCloseContactUs } from 'store/reducers/contactUs';
import ModalContactUs from './ModalContactUs';

const ModalContactUsDispatcher = () => {
  const { dispatch, isOpen, subject } = useMainContext();
  const onClose = () => {
    dispatch(setCloseContactUs());
  };
  if (!isOpen) return null;
  return (
    <ModalContactUs onClose={onClose} subject={subject} />
  );
};

export default ModalContactUsDispatcher;

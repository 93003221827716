import styled from 'styled-components';

import { DEFAULT } from 'colors';

export const Menu = styled.div`
  position: absolute;
  top: 24px;
  right: 48px;
`;

export const Div = styled.div`
  cursor: pointer;
  display: flex;
  > button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  > button.login > span {
    font-weight: 600;
    font-size: 14px;
    color: ${DEFAULT};
  }
  > button.login, > button.upgrade {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
  }
  > button.upgrade {
    background: linear-gradient(94.49deg, #EB4076 1.12%, #7933FE 116.5%);
    border-radius: 20px;
    margin-right: 15px;
  }
  > button.upgrade > img {
    width: 16px;
    height: 16px;
  }
  > button.upgrade > span, > button.upgrade > div {
    display: flex;
    flex-direction: row;
    padding: 2px 10px 2px 4px;
    min-width: 106px;
    background: transparent;
    border-width: 0px;
    text-align: center;
    justify-content: center;
    color: white;
    margin-right: 5px;
    font-weight: 600;
    font-size: 14px;
    margin: 0px 5px;
  }
  > button.upgrade > div {
    > div.loader {
      border: 2px solid #f3f3f3;
      border-top: 2px solid #3498db;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      animation: spin 2s linear infinite;
      margin-right: 5px;
    }
  }
  > button.upgrade > button:hover {
    transform: scale(1.05);
  }
  > div.name {
    display: flex;
  }
  > div.name > span {
    padding: 2px 10px;
    font-weight: 600;
    font-size: 14px;
    color: ${DEFAULT};
    margin: 0px 10px;
    text-align: center;
    align-self: center;
  }
  > div.name > img.user {
    max-width: 20px;
  }
  > div.name > img.down {
    max-width: 8px;
  }
  > img {
    display: none;
  }
  > div > div {
    margin-top: 8px;
    margin-left: 10px;
    height: 1px;
    border: solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
  }
  @media (max-width: 800px) {
    > img {
      display: block;
    }
    > div.name, > div.upgrade {
      display: none;
    }
    > div > div, > div > span {
      display: none;
    }
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export const MenuDiv = styled.div`
  animation-name: dropdownAnimation;
  animation-duration: .5s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  position: absolute;
  background: white;
  box-shadow: 0px 28px 80px rgba(0, 0, 0, 0.02), 0px 10.2205px 29.2013px rgba(0, 0, 0, 0.0137996), 0px 4.96184px 14.1767px rgba(0, 0, 0, 0.0111258), 0px 2.43239px 6.94968px rgba(0, 0, 0, 0.00887419), 0px 0.96177px 2.74791px rgba(0, 0, 0, 0.00620037);
  border-radius: 12px;
  float: none;
  display: block;
  top: auto;
  right: 0;
  left: auto;
  z-index: 5;
  min-width: 100px;
  padding: 8px 0;
  margin: 2px 0 0;
  font-size: 12px;
  color: #263238;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  > button {
    cursor: pointer;
    transition: background 0.1s ease-in-out, color 0.1s ease-in-out;
    display: flex;
    width: 100%;
    padding: 10px 24px;
    clear: both;
    font-weight: 400;
    color: #495057;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
  }
  > button:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }
  > button.upgrade, > div.separator {
    display: none;
  }
  > div {
    height: 0;
    margin: 8px 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
  }
  > button > img {
    height: 20px;
    margin: 0px 10px 0 0;
  }
  > button > span {
    color: ${DEFAULT};
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
  }
  span.user-name {
    color: #16181b;
    margin-left: 10px;
    padding-bottom: 2px;
  }
  @media (max-width: 800px) {
    > button.upgrade, > div.separator {
      display: block;
    }
  }
`;

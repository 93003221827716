import styled from 'styled-components';
import cookie from 'images/cookie.png';

export const Div = styled.div`
  position: fixed;
  bottom: 0px;
  text-align: center;
  width: 100%;
  height: 57px;
  z-index: 3;
  font-family: &quot;
  Source Sans Pro&quot;;
  background: url(${cookie}) 20px 50% / 30px 30px no-repeat rgba(52, 64, 81, 0.88);
  font-size: 15px;
  font-weight: 600;
`;

export const Span = styled.span`
  line-height: 1.3;
  font-weight: 500;
  color: white;
  display: block;
  padding: 9px 67px;
  text-align: left;
  margin-right: 244px;
`;

export const Button = styled.button`
  position: absolute;
  top: 50%; right: 20px;
  line-height: 32px;
  margin-top: -18px;
  padding: 0px 8px;
  background: transparent;
  border: 1px solid white;
  border-radius: 4px;
  box-shadow: none;
  font-size: 14px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  width: 66px;
  height: 32px;
  opacity: 1;
`;

export const Link = styled.a`
  color: rgb(240, 240, 240);
  text-decoration: none;
  margin-left: 10px;
  font-weight: bold;
`;

import React from 'react';

import storageIcon from 'images/myDocuments/storage.svg';
import { Div } from './elements';

interface StorageProps {
  total: number;
  used: number;
  isOpen: boolean;
}
const getPercentage = (total: number, used: number): string =>
  `${Math.round(used * 100 / total)}%`;

const getValueRoundedInScale = (value: number, scale: number): number => Math.round(value * 100 / scale) / 100;
const getValueInKB = (value: number): number => getValueRoundedInScale(value, 1024);
const getValueInMB = (value: number): number => getValueRoundedInScale(value, 1024 * 1024);
const getValueInGB = (value: number): number => getValueRoundedInScale(value, 1024 * 1024 * 1024);

const getValueWithScale = (value: number): string => {
  if (value < 1024 * 512) return `${getValueInKB(value)}KB`;
  if (value < 1024 * 1024 * 512) return `${getValueInMB(value)}MB`;
  return `${getValueInGB(value)}GB`;
};

const getValuesWithUniqueScale = (total: number, used: number): string => {
  if (total < 1024 * 512) return `${getValueInKB(used)}/${getValueInKB(total)}KB`;
  if (total < 1024 * 1024 * 512) return `${getValueInMB(used)}/${getValueInMB(total)}MB`;
  return `${getValueInGB(used)}/${getValueInGB(total)}GB`;
};

const Storage = ({total, used, isOpen}: StorageProps) => {
  const style = {width: getPercentage(total, used)};
  return (
    <Div $isOpen={isOpen}>
      <div className="cloud">
        <img src={storageIcon} alt="storage" width="20" height="13.33" />
        {isOpen && <span>Storage</span>}
      </div>
      <div className="bar">
        <div style={style} />
      </div>
      {isOpen? (
        <span>{getValueWithScale(used)} of {getValueWithScale(total)} used</span>
        ) : (
        <span>{getValuesWithUniqueScale(total, used)}</span>
      )}
    </Div>
  );
};

export default Storage;


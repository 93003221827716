import { TutorialBox } from '../tutorialsTypes';

const exploreWords: TutorialBox[][] = [
  [{
    top: "320px",
    left: "240px",
    content: ['You can find out more about the level', 'of words here']
  }], [{
    top: "-110px",
    left: "140px",
    content: ['Simply enter an English word in the box', 'and hit ‘explore’']
  }], [{
    top: "-160px",
    left: "240px",
    doNext: true,
    hasButtonNext: true,
    buttonNextText: 'Next',
    content: ['You will get more information here on the', 'level of the word']
  }], [{
    bottom: "100px",
    left: "240px",
    doNext: true,
    hasButtonNext: true,
    buttonNextText: 'Got it, thanks!',
    content: ['And can see alternative words to use here']
  }]
];

export default exploreWords;

import React, { useState } from 'react';

import { useMainContext } from 'routes/MainContextProvider';
import ModalMobileWarning from './ModalMobileWarning';

const isMobile = window.screen.width < 1000;

const ModalMobileWarningDispatcher = () => {
  const { local } = useMainContext();
  const [isAccepted, setIsAccepted] = useState<boolean>(local.mobileWarning.getLocalStorageMobileWarning());
  if (isAccepted || !isMobile) return null;
  const onAccept = () => {
    local.mobileWarning.setLocalStorageMobileWarning();
    setIsAccepted(true)
  };
  return (
    <ModalMobileWarning onClose={onAccept} />
  );
};

export default ModalMobileWarningDispatcher;

import type { LocalMobileWarning, LocalStorageFunctions } from './types';

const MOBILE_WARNING: string = 'mobile-warning';

const getLocalStorageMobileWarning = (fn: LocalStorageFunctions) => (): boolean => !!fn.readObject(MOBILE_WARNING);
const setLocalStorageMobileWarning = (fn: LocalStorageFunctions) => () => fn.writeObject(MOBILE_WARNING, true);

const mobileWarning = (fn: LocalStorageFunctions): LocalMobileWarning => ({
  getLocalStorageMobileWarning: getLocalStorageMobileWarning(fn),
  setLocalStorageMobileWarning: setLocalStorageMobileWarning(fn)
});

export default mobileWarning;

import type { LocalStorageFunctions, LocalWebCheckerEcommerceDetails } from './types';

const WEB_CHECKER_ECOMMERCE_DETAILS: string = 'web-checker-ecommerce-details';

const getLocalStorageWebCheckerEcommerceDetails = (fn: LocalStorageFunctions) => (url: string): string | null => {
  const choice = fn.readObject(WEB_CHECKER_ECOMMERCE_DETAILS);
  if (!choice?.[url]) return null;
  return choice[url];
};

const setLocalStorageWebCheckerEcommerceDetails = (fn: LocalStorageFunctions) => (url: string, id: string) => {
  let choice = fn.readObject(WEB_CHECKER_ECOMMERCE_DETAILS);
  if (!choice) {
    choice = {};
  }
  choice[url] = id;
  fn.writeObject(WEB_CHECKER_ECOMMERCE_DETAILS, choice);
};

const delLocalStorageWebCheckerEcommerceDetails = (fn: LocalStorageFunctions) => (url: string) => {
  const choice = fn.readObject(WEB_CHECKER_ECOMMERCE_DETAILS);
  if (!choice?.[url]) return;
  delete choice[url];
  fn.writeObject(WEB_CHECKER_ECOMMERCE_DETAILS, choice);
};

const webCheckerEcommerceDetails = (fn: LocalStorageFunctions): LocalWebCheckerEcommerceDetails => ({
  getLocalStorageWebCheckerEcommerceDetails: getLocalStorageWebCheckerEcommerceDetails(fn),
  setLocalStorageWebCheckerEcommerceDetails: setLocalStorageWebCheckerEcommerceDetails(fn),
  delLocalStorageWebCheckerEcommerceDetails: delLocalStorageWebCheckerEcommerceDetails(fn)
});

export default webCheckerEcommerceDetails;

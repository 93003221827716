import type { LocalCookies, LocalStorageFunctions } from './types';

const COOKIES: string = 'cookies';

const getLocalStorageCookies = (fn: LocalStorageFunctions) => (): boolean => fn.readObject(COOKIES);
const setLocalStorageCookies = (fn: LocalStorageFunctions) => () => fn.writeObject(COOKIES, true);

const cookies = (fn: LocalStorageFunctions): LocalCookies => ({
  getLocalStorageCookies: getLocalStorageCookies(fn),
  setLocalStorageCookies: setLocalStorageCookies(fn)
});

export default cookies;

import React from 'react';
import styled from 'styled-components';

const Footer = () => (
  <FooterDiv>
    <div>
      <ul className="links">
        <li>
          <a href="/privacy" target="_blank" rel="noreferrer" aria-label="Privacy Policy">
            Privacy Policy
          </a>
        </li>
        <li>
          <a href="/terms" target="_blank" rel="noreferrer" aria-label="Terms">
            Terms
          </a>
        </li>
        <li>
          <a href="/faq" target="_blank" rel="noreferrer" aria-label="FAQ">
            FAQ
          </a>
        </li>
        <li>
          <a href="https://www.tigim.co/about-us" target="_blank" rel="noreferrer" aria-label="About us">
            About us
          </a>
        </li>
        <li>
          <a href="/how-to" target="_blank" rel="noreferrer" aria-label="How to Guide">
            How to Guide
          </a>
        </li>
        <li>
          <a href="/glossary/All" target="_blank" rel="noreferrer" aria-label="Glossary">
            Glossary
          </a>
        </li>
      </ul>
      <p>
        Copyright © 2022 Tigim.co
      </p>
    </div>
  </FooterDiv>
);

export default Footer;

const FooterDiv = styled.footer`
  position: block;
  display: grid;
  grid-template-columns: 32px 885px 1fr;
  gap: 24px;
  bottom: 0px;
  width: 100%;
  z-index: 2;
  > div {
    grid-column: 2;
    position: relative;
    padding: 0px 12px;
    display: block;
    color: #495057;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  > div > ul {
    display: flex;
    flex-direction: row;
    width: fit-content;
    align-self: center;
    padding-left: 0;
    list-style: none;
    margin-bottom: 0px;
  }
  > div > ul > li {
    display: inline-block;
    align-self: center;
  }
  > div > ul > li > a {
    display: block;
    font-weight: 600;
    color: #636D75;
    font-size: 12px;
    position: relative;
    text-decoration: none;
    background-color: transparent;
  }
  > div > ul.icons > li > a {
    height: 24px;
  }
  > div > ul.icons > li {
    margin-right: 10px;
    margin-left: 10px;
  }
  > div > ul.links > li {
    margin-right: 10px;
    margin-left: 10px;
  }
  > div > p {
  }
  @media (max-width: 800px) {
    > div {
      grid-column: 1;
      grid-column-start: span 3;
    }
  }
`;

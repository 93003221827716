import React, { useCallback, useContext, useMemo, useState } from 'react';
import { ToastrEmitter, ConfirmToastrOptions, BasicToastrOptions, LightToastrOptions } from 'react-redux-toastr';
import type { Dispatch } from '@reduxjs/toolkit';

import noop from 'functions/general/noop';
import TUTORIALS from 'constants/tutorials';
import defaultLocal from 'services/local/defaultLocal';
import type { AnalysisResponse } from  'pages/NewDocument/types';
import type { IntCompleteReport } from  'pages/Analysis/types';
import type { Local, IntUserResponse } from 'services/local/types';
import type { TutorialBox } from 'constants/tutorialsTypes';
import type { ApiHTTP } from 'services/http/types';
import promiseWrapper from 'functions/general/promiseWrapper';
import apiHttp from 'services/http/apiHttp';
import axios from 'axios';

export type MainContextIntBase = {
  openLoading: (message: string) => void;
  closeLoading: () => void;
  history: (path: string) => void;
  dispatch: Dispatch
  scrollToTop(): void;
  toastr: ToastrEmitter;
  local: Local;
  api: ApiHTTP;
  editMode: boolean;
  user?: IntUserResponse;
  isLoggedIn: boolean;
  pendingCredits: boolean;
  signIn: boolean;
  signUp: boolean;
  isLoading: boolean;
  message: string;
  isOpen: boolean;
  subject: string;
  componentName: string;
  isOpenContactLogin: boolean;
  subjectContactLogin: string;
  name: string;
  showMenu: boolean;
  used: number;
  total: number;
  analysisReport?: AnalysisResponse;
  historyReport?: IntCompleteReport;
  sendEmail: boolean;
  latinize(str: string): string;
}

export interface MainContextInt extends MainContextIntBase {
  tutorialBoxes: TutorialBox[] | undefined;
  nextStep: () => void;
  clearTutorials: () => void;
  tutorialStep: number;
  tutorial: string;
  setTutorial: (tutorial: string) => void;
  findOutMore: string;
  setFindOutMore: (findOutMore: string) => void;
  howToConcept: string;
  setHowToConcept: (howToConcept: string) => void;
}

const defaultDispath: Dispatch<any> = () => {
  return {a: 0} as any;
};

export const defaultToastr: ToastrEmitter = {
  clean: () => noop(),
  confirm: (_message: string, _options: ConfirmToastrOptions) => noop(),
  error: (_title: string, _message: string, _options?: BasicToastrOptions) => noop(),
  info: (_title: string, _message: string, _options?: BasicToastrOptions) => noop(),
  light: (_title: string, _message: string, _options?: LightToastrOptions) => noop(),
  message: (_title: string, _message: string, _options?: BasicToastrOptions) => noop(),
  removeByType: (_type: string) => noop(),
  success: (_title: string, _message: string, _options?: BasicToastrOptions) => noop(),
  warning: (_title: string, _message: string, _options?: BasicToastrOptions) => noop()
}

const mainContextDefault: MainContextIntBase = {
  openLoading: (_message: string) => {
    // This is intentional
  },
  closeLoading: noop,
  history: (_path: string) => {
    // This is intentional
  },
  dispatch: defaultDispath,
  scrollToTop: () => {
    // This is intentional
  },
  toastr: defaultToastr,
  local: defaultLocal,
  api: apiHttp(axios, promiseWrapper(defaultToastr)),
  used: 0,
  total: 0,
  editMode: false,
  componentName: '',
  showMenu: true as boolean,
  isLoggedIn: false as boolean,
  pendingCredits: false as boolean,
  signIn: false as boolean,
  signUp: false as boolean,
  isLoading: false as boolean,
  message: '' as string,
  isOpen: false as boolean,
  subject: '' as string,
  isOpenContactLogin: false as boolean,
  subjectContactLogin: '' as string,
  name: '' as string,
  sendEmail: false as boolean,
  latinize: (str: string) => str
};

const MainContext = React.createContext(mainContextDefault as MainContextInt);

export const useMainContext = () => useContext(MainContext);

export interface MainContextProviderProps {
  children: string | React.ReactNode;
  value: MainContextIntBase;
}

const MainContextProvider = ({children, value}: MainContextProviderProps) => {
  const [tutorialStep, setTutorialStep] = useState<number>(0);
  const [tutorial, setTutorial] = useState<string>('');
  const [findOutMore, setFindOutMore] = useState<string>('');
  const [howToConcept, setHowToConcept] = useState<string>('');
  const tutorialBoxes: TutorialBox[] | undefined = TUTORIALS[tutorial]?.[tutorialStep];

  const nextStep = useCallback(() => {
    if (TUTORIALS[tutorial] && tutorialStep < TUTORIALS[tutorial].length - 1) {
      setTutorialStep(tutorialStep + 1);
    } else {
      value.local.tutorials.setTutorialIsDone(tutorial);
      setTutorialStep(0);
      setTutorial('');
    }
  }, [tutorial, tutorialStep, value]);

  const startTutorial = (tutorialItem: string) => {
    setTutorial(tutorialItem);
    setTutorialStep(0);
  };

  const clearTutorials = useCallback(() => {
    value.local.tutorials.clearTutorials();
    setTutorialStep(0);
    setTutorial('');
  }, [value, setTutorial, setTutorialStep]);

  const val: MainContextInt = useMemo(() => ({
    ...value,
    tutorialBoxes,
    setTutorial: startTutorial,
    clearTutorials,
    nextStep,
    tutorialStep,
    tutorial,
    findOutMore,
    setFindOutMore,
    howToConcept,
    setHowToConcept
  }), [value, tutorialBoxes, tutorialStep, tutorial, findOutMore, howToConcept, clearTutorials, nextStep]);

  return (
    <MainContext.Provider value={val}>
      {children}
    </MainContext.Provider>
  );
};

export default MainContextProvider;

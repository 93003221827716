import React from 'react';

import { useMainContext } from 'routes/MainContextProvider';
import FullscreenLoaderSVG from './index';

const Loading = () => {
  const { isLoading, message } = useMainContext();
  return (
    <FullscreenLoaderSVG text={message} show={isLoading}/>
  );
};

export default Loading;

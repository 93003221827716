import { TutorialBox } from '../tutorialsTypes';

const exporting: TutorialBox[][] = [
  [{
    top: "270px",
    left: "240px",
    content: ['Click here to go to your documents']
  }], [{
    top: "40px",
    left: "-250px",
    doNext: true,
    hasButtonNext: true,
    buttonNextText: 'Next',
    content: ['Move the cursor over your', 'document to see more options']
  }], [{
    bottom: "0px",
    left: "250px",
    content: ['Click on the menu dots to see', 'more options']
  }], [{
    bottom: "-230px",
    left: "250px",
    content: ['You can download a more', 'detailed report in Excel']
  }]
];

export default exporting;

import React from 'react';

import ErrorBoundary from 'ErrorBoundary';
import getLocal from 'services/getLocal';
import FullscreenLoaderSVG from 'components/atoms/FullscreenLoaderSVG';
import MainContextProviderProduction from './MainContextProviderProduction';

type Meta = {
  auth?: boolean;
  notAuth?: boolean;
  admin?: boolean;
  notAdmin?: boolean;
  licenced?: boolean;
  payments?: boolean;
}

const requireLogin = (meta: Meta): string => {
  const user = getLocal(localStorage).auth.getUser();
  const isLoggedIn = user?.user?.email;
  if (meta.auth) {
    if (!isLoggedIn) {
      return '/analysis';
    }
  } else if (meta.notAuth) {
    if (isLoggedIn) {
      return '/';
    }
  }
  return '';
};

const requireAdmin = (meta: Meta): string => {
  const isAdmin = getLocal(localStorage).auth.isAdmin();
  if (meta.admin) {
    if (!isAdmin) {
      return '/analysis';
    }
  } else if (meta.notAdmin) {
    if (isAdmin) {
      return '/admin/users-administration';
    }
  }
  return '';
};

const requireLicenced = (meta: Meta): string => {
  if (meta.licenced) {
    const hasLicence = getLocal(localStorage).auth.hasLicence();
    if (!hasLicence) {
      return '/account';
    }
  }
  return '';
}

const requirePayments = (meta: Meta): string => {
  if (meta.payments) {
    const isPrepaid = getLocal(localStorage).auth.isPrepaid();
    const hasOnlyLicence = getLocal(localStorage).auth.hasOnlyLicence();
    if (isPrepaid || hasOnlyLicence) {
      return '/analysis';
    }
  }
  return '';
}

const guards = [requireLogin, requireAdmin, requireLicenced, requirePayments];

export type ProtectorProps = {
  name: string;
  elem: React.LazyExoticComponent<() => React.JSX.Element | null>;
  meta?: Meta;
}

const ProtectorProduction = ({ meta, elem, name }: ProtectorProps) => {
  const metaPro = meta ?? {};
  let res: string = '';
  for (let guard of guards) {
    res = guard(metaPro);
    if (res.length > 0) {
      break;
    }
  }
  if (res.length > 0) {
    window.location.replace(res)
    return null;
  }
  const Comp = elem;
  return (
    <ErrorBoundary componentName={name}>
      <MainContextProviderProduction componentName={name}>
        <Comp />
      </MainContextProviderProduction>
    </ErrorBoundary>
  );
};

export default ProtectorProduction;

export const Loader = () => (
  <MainContextProviderProduction componentName="loading">
    <FullscreenLoaderSVG text="Loading..." show={true}/>
  </MainContextProviderProduction>
);
import { TutorialBox } from '../tutorialsTypes';

const addComments: TutorialBox[][] = [
  [{
    top: "80px",
    right: "120px",
    content: ['You can add comments to your', 'documents in edit mode']
  }], [{
    top: "-40px",
    right: "320px",
    content: ['Highlight a word or sentence and', 'right click on it']
  }], [{
    top: "-80px",
    right: "-200px",
    content: ['Now you can write something helpful', 'and add it to the document']
  }], [{
    top: "-20px",
    right: "80px",
    content: ['You can use this button to see or hide', 'the comments']
  }], [{
    top: "100px",
    right: "120px",
    doNext: true,
    hasButtonNext: true,
    buttonNextText: 'Got it, thanks!',
    content: ['Click on the speech bubble to read', 'and respond']
  }]
];
    
export default addComments;

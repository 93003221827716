import { DEFAULT } from 'colors';
import { LeftMenuLogoProps } from './types';

const getLogo = (open: boolean) => open? <LogoFull />: <LogoShort />;

const LeftMenuLogo = ({open, className=''}: LeftMenuLogoProps) => (
  <div className={`logo ${className}`}>
    <a href='https://www.tigim.co' target="_blank" rel="noreferrer" aria-label="Tigim">
    {getLogo(open)}
    </a>
  </div>
);

export default LeftMenuLogo;

const LogoFull = () => (
  <svg width="144" height="41" viewBox="2 0 144 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="109.643" width="33.7122" height="14.6086" rx="2.24748" fill="#E2E9F3"/>
    <path d="M115.003 11V3.07764H118.464C119.296 3.07764 119.94 3.26118 120.397 3.62827C120.854 3.98787 121.082 4.4898 121.082 5.13408C121.082 5.5611 120.974 5.92819 120.756 6.23535C120.539 6.5425 120.24 6.76725 119.857 6.90959C120.307 7.03695 120.655 7.2617 120.903 7.58384C121.157 7.89848 121.285 8.29928 121.285 8.78624C121.285 9.49045 121.045 10.0373 120.565 10.4269C120.093 10.809 119.434 11 118.588 11H115.003ZM116.385 6.43762H118.217C119.198 6.43762 119.689 6.05929 119.689 5.30264C119.689 4.54599 119.198 4.16766 118.217 4.16766H116.385V6.43762ZM116.385 9.90998H118.408C119.397 9.90998 119.891 9.51292 119.891 8.71881C119.891 7.9247 119.397 7.52765 118.408 7.52765H116.385V9.90998Z" fill={DEFAULT} />
    <path d="M125.313 11.1124C124.691 11.1124 124.156 10.9963 123.706 10.764C123.257 10.5318 122.908 10.2022 122.661 9.77513C122.421 9.34811 122.301 8.84242 122.301 8.25808C122.301 7.68872 122.417 7.19053 122.65 6.76351C122.889 6.33648 123.215 6.00311 123.627 5.76338C124.047 5.51615 124.523 5.39254 125.055 5.39254C125.834 5.39254 126.448 5.63977 126.897 6.13421C127.354 6.62866 127.583 7.3029 127.583 8.15694V8.57273H123.65C123.755 9.55413 124.317 10.0448 125.335 10.0448C125.643 10.0448 125.95 9.99988 126.257 9.90998C126.564 9.81259 126.845 9.66276 127.1 9.46048L127.493 10.4044C127.231 10.6217 126.901 10.794 126.504 10.9213C126.107 11.0487 125.71 11.1124 125.313 11.1124ZM125.111 6.34772C124.699 6.34772 124.365 6.47508 124.111 6.72979C123.856 6.98451 123.702 7.32912 123.65 7.76363H126.414C126.384 7.30665 126.257 6.95829 126.032 6.71856C125.815 6.47133 125.508 6.34772 125.111 6.34772Z" fill={DEFAULT} />
    <path d="M131.191 11.1124C129.737 11.1124 129.011 10.3932 129.011 8.9548V6.56123H127.954V5.50492H129.011V3.86425H130.415V5.50492H132.078V6.56123H130.415V8.87614C130.415 9.23573 130.494 9.50543 130.651 9.68523C130.809 9.86503 131.063 9.95493 131.415 9.95493C131.52 9.95493 131.629 9.94369 131.741 9.92122C131.854 9.89125 131.97 9.86128 132.09 9.83132L132.303 10.8652C132.168 10.9401 131.996 11 131.786 11.045C131.584 11.0899 131.385 11.1124 131.191 11.1124Z" fill={DEFAULT} />
    <path d="M134.795 11.1124C134.398 11.1124 134.043 11.0375 133.728 10.8876C133.421 10.7303 133.177 10.5205 132.997 10.2583C132.825 9.99613 132.739 9.70021 132.739 9.37058C132.739 8.96604 132.844 8.64764 133.054 8.4154C133.263 8.17567 133.604 8.00337 134.076 7.89848C134.548 7.7936 135.181 7.74116 135.975 7.74116H136.369V7.50517C136.369 7.13059 136.286 6.8609 136.121 6.69608C135.957 6.53127 135.679 6.44886 135.29 6.44886C134.983 6.44886 134.668 6.49755 134.346 6.59494C134.024 6.68484 133.698 6.82718 133.368 7.02197L132.964 6.06679C133.159 5.93194 133.387 5.81582 133.649 5.71843C133.919 5.61354 134.2 5.53488 134.492 5.48244C134.792 5.42251 135.073 5.39254 135.335 5.39254C136.136 5.39254 136.732 5.57983 137.122 5.95441C137.511 6.3215 137.706 6.89461 137.706 7.67374V11H136.391V10.1235C136.264 10.4306 136.062 10.6741 135.784 10.8539C135.507 11.0262 135.177 11.1124 134.795 11.1124ZM135.088 10.146C135.455 10.146 135.758 10.0186 135.998 9.76389C136.245 9.50918 136.369 9.18704 136.369 8.79748V8.55025H135.987C135.282 8.55025 134.792 8.60644 134.514 8.71881C134.245 8.8237 134.11 9.01848 134.11 9.30316C134.11 9.55038 134.196 9.75266 134.368 9.90998C134.541 10.0673 134.78 10.146 135.088 10.146Z" fill={DEFAULT} />
    <path d="M48.3743 31.7223H52.0762V15.4565H58.3021V12.0911C52.609 12.0911 47.8134 12.0911 42.1484 12.0911V15.4565H48.3743V31.7223Z" fill={DEFAULT} />
    <path d="M63.4474 17.8403V31.7223H60.0259V17.8403H63.4474ZM59.7174 13.9982C59.7174 16.6624 63.7558 16.6624 63.7558 13.9982C63.7558 11.3339 59.7174 11.3339 59.7174 13.9982Z" fill={DEFAULT} />
    <path d="M76.0231 18.2329C75.0696 17.5879 73.9478 17.4476 72.8541 17.4476C68.984 17.4476 65.843 20.1399 65.843 24.4588C65.843 28.7776 68.5352 31.4138 72.8541 31.4138C74.5648 31.4138 76.3597 32.2832 76.3597 34.1902C76.3597 36.0972 74.8172 37.1629 72.8541 37.1629C70.891 37.1629 69.2364 35.9851 69.2364 34.1902H65.843C65.843 37.9201 68.8437 40.36 72.8541 40.36C76.8645 40.36 79.7811 38.0043 79.7811 34.1902C79.7811 32.4234 79.2202 30.7688 76.9206 29.619C79.1922 28.5813 79.8372 26.1695 79.8372 24.4588C79.8372 22.9444 79.4446 21.5702 78.3789 20.3643L79.9213 18.4012L77.3973 16.4941L76.0231 18.2329ZM72.8541 20.5606C74.8172 20.5606 76.4438 22.075 76.4438 24.4588C76.4438 26.8426 74.8172 28.3009 72.8541 28.3009C70.8629 28.3009 69.2364 26.8706 69.2364 24.4588C69.2364 22.0469 70.8629 20.5606 72.8541 20.5606Z" fill={DEFAULT} />
    <path d="M85.4393 17.8403V31.7223H82.0179V17.8403H85.4393ZM81.7094 13.9982C81.7094 16.6624 85.7478 16.6624 85.7478 13.9982C85.7478 11.3339 81.7094 11.3339 81.7094 13.9982Z" fill={DEFAULT} />
    <path d="M101.156 31.7223V24.3185C101.156 22.4957 102.306 20.8971 104.157 20.8971C106.008 20.8971 106.765 22.4396 106.765 24.2625V31.7223H110.186V24.2625C110.186 20.1119 108.251 17.7 104.325 17.7C102.53 17.7 100.932 18.2609 99.6977 20.224C98.9125 18.317 97.2579 17.644 95.6032 17.644C94.2851 17.644 92.6866 18.1488 91.9574 19.551L91.705 17.8683H88.536V31.7223H91.9574V24.3185C91.9574 22.4957 93.0512 20.841 94.9021 20.841C96.7811 20.841 97.7346 22.4957 97.7346 24.3185V31.7223H101.156Z" fill={DEFAULT} />
    <g transform='scale(0.24) translate(52, 35)'>
      <rect x="0" y="0" width="45" height="45" rx="8" ry="8" fill='#5E18EB' />
      <polygon points="22.5,4 5,40 40,40" fill='#FBC11E'/>
      <circle cx="77.5" cy="22.5" r="22.5" fill='#31D3E1'/>
      <g transform="translate(38.5 -40)">
        <rect transform="rotate(45)" x="57.5" y="2.5" width="30" height="30" rx="2" ry="2" fill='#EC3F75'/>
      </g>
      <rect x="0" y="55" width="45" height="45" rx="8" ry="8" fill='#EC3F75'/>
      <g transform="translate(4 55)">
        <g transform="scale(0.1)">
          <polygon points="0,121.32 184.152,15.544 368.312,121.32 368.312,332.864 184.152,438.64 0,332.864" fill='#31D3E1'/>
        </g>
      </g>
      <rect x="55" y="55" width="45" height="45" rx="8" ry="8" fill='#FBC11E' />
      <circle cx="77.5px" cy="77.5px" r="20px" fill='#5E18EB' />
    </g>
  </svg>
);
  

const LogoShort = () => (
  <svg width="36" height="36" viewBox="2 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g transform='scale(0.24) translate(56, 15)'>
      <rect x="0" y="0" width="45" height="45" rx="8" ry="8" fill='#5E18EB' />
      <polygon points="22.5,4 5,40 40,40" fill='#FBC11E'/>
      <circle cx="77.5" cy="22.5" r="22.5" fill='#31D3E1'/>
      <g transform="translate(38.5 -40)">
        <rect transform="rotate(45)" x="57.5" y="2.5" width="30" height="30" rx="2" ry="2" fill='#EC3F75'/>
      </g>
      <rect x="0" y="55" width="45" height="45" rx="8" ry="8" fill='#EC3F75'/>
      <g transform="translate(4 55)">
        <g transform="scale(0.1)">
          <polygon points="0,121.32 184.152,15.544 368.312,121.32 368.312,332.864 184.152,438.64 0,332.864" fill='#31D3E1'/>
        </g>
      </g>
      <rect x="55" y="55" width="45" height="45" rx="8" ry="8" fill='#FBC11E' />
      <circle cx="77.5px" cy="77.5px" r="20px" fill='#5E18EB' />
    </g>
  </svg>
);

import { useEffect, useState } from 'react';

import { useMainContext } from 'routes/MainContextProvider';
import { setCredits } from 'store/reducers/auth';
import useMounted from 'hooks/useMounted';
import type { IntUser } from 'services/local/types';

const usePendingCredits = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { api, pendingCredits, dispatch, local } = useMainContext();
  const isMounted = useMounted();

  useEffect(() => {
    if (!isLoading && pendingCredits) {
      setIsLoading(true);
      api.auth.getAccountSettings().then((response: IntUser) => {
        if (isMounted()) {
          local.auth.setUserUser(response);
          const user = local.auth.getUser();
          if (user) {
            user.user = response;
            dispatch(setCredits(user));
          }
          setIsLoading(false);
        }
      });
    }
  }, [isLoading, pendingCredits, api, setIsLoading, dispatch, local, isMounted]);

  return isLoading;
};

export default usePendingCredits;

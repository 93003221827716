const getIntegerFormatted = (value: number | undefined | null): string => {
  if (value === undefined || value === null) return '';
  const sign = (value < 0) ? '-' : '';
  const absValue = Math.abs(value);
  const str = absValue.toString();
  if (absValue < 1000) return `${sign}${str}`;
  if (absValue < 10000) return `${sign}${getIntegerFormatted10K(str)}`;
  if (absValue < 100000) return `${sign}${getIntegerFormatted100K(str)}`;
  if (absValue < 1000000) return `${sign}${getIntegerFormatted1M(str)}`;
  if (absValue < 10000000) return `${sign}${getIntegerFormatted10M(str)}`;
  return `${sign}${getIntegerFormatted100M(str)}`;
};

export default getIntegerFormatted;

const getIntegerFormatted10K = (value: string): string => {
  const first = value.slice(0, 1);
  const last = value.slice(1, 4);
  return `${first},${last}`;
};

const getIntegerFormatted100K = (value: string): string => {
  const first = value.slice(0, 2);
  let last = value.slice(2, 5);
  if (Math.round(Number(last) / 100) === 10) return `${Number(first) + 1}K`;
  last = (Math.round(Number(last) / 100)).toString(); 
  return `${first}.${last}K`;
};

const getIntegerFormatted1M = (value: string): string => `${value.slice(0, 3)}K`;

const getIntegerFormatted10M = (value: string): string => {
  const first = value.slice(0, 1);
  let last = value.slice(1, 4);
  if (Math.round(Number(last) / 100) === 10) return `${Number(first) + 1}M`;
  last = (Math.round(Number(last) / 100)).toString(); 
  return `${first}.${last}M`;
};

const getIntegerFormatted100M = (value: string): string => `${value.slice(0, value.length - 6)}M`;
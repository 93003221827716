import styled from 'styled-components';
import { ModalDiv } from 'components/atoms/Modal/elements';

export const ThankDiv = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  text-align: center;
  > p {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: black;
  }
  > p.title {
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    color: black;
  }
  > p.title > span {
    font-size: 36px;
  }
`;

export const FooterDiv = styled.div`
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  text-align: center;
  > p {
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: black;
  }
  > a {
    text-decoration: none;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #7933FE;
  }
`;

export const ModalBody = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 27px 18px;
  button.close {
    padding: 16px 16px;
    margin: -16px -16px -16px auto;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    float: right;
    font-size: 21px;
    font-weight: 600;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
  }
  button.close > span {
    cursor: pointer;
  }
`;

export const ModalBodyMobileWarning = styled(ModalDiv)`
  max-width: 100%;
  @media (max-width: 500px) {
    margin-left: 0px;
    margin-right: 0px;
  }
  > div {
    align-self: start;
    border-radius: 6px;
    min-height: 503px;
  }
`;

import { TutorialBox } from '../tutorialsTypes';

const editing: TutorialBox[][] = [
  [{
    top: "80px",
    right: "120px",
    content: ['To edit your document, click on this', 'icon']
  }], [{
    top: "0px",
    left: "420px",
    doNext: true,
    content: ['You will get a side-by-side view to', 'compare with the analysis']
  }], [{
    top: "-20px",
    left: "-240px",
    content: ['You can click here to work on the', 'document without the comparison']
  }], [{
    top: "-20px",
    left: "-280px",
    doNext: true,
    hasButtonNext: true,
    buttonNextText: 'Got it, thanks!',
    content: ['Click on it again to return to the', 'side-by-side view']
  }]
];

export default editing;

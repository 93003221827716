export const DEFAULT_ITEMS: string[] = [
  'processing',
  'readability',
  'complexity',
  'jargon',
  'diversity',
  'engagement',
  'empathy',
  'formality',
  'directness',
  'sentimentLevel',
  'sentimentType',
  'textStyle'];

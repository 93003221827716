export const DEFAULT_ITEMS: string[] = [
  'Readability',
  'Difficult Words',
  'Difficult Words %',
  'Difficult Sentences',
  'Everyday Words',
  'Average Sentence Length',
  'Words (tokens)',
  'Positive Word',
  'Negative Word',
  'Personal Pronoun',
  'Empathy Phrase',
  'Hedging Word',
  'Booster Word',
  'Sensory Word'
];
  							
import { TutorialBox } from '../tutorialsTypes';

const filteringGroups: TutorialBox[][] = [
  [{
    top: "-20px",
    left: "-350px",
    hasGrettings: true,
    content: ['Double click the highlighted category']
  }], [{
    top: "60px",
    right: "40px",
    doNext: true,
    content: ['This way, you can filter a', 'particular group in the text']
  }], [{
    top: "-20px",
    left: "-220px",
    doubleClick: true,
    content: ['Simply double click again', 'to see all groups']
  }], [{
    top: "200px",
    left: "250px",
    doNext: true,
    hasButtonNext: true,
    buttonNextText: 'Got it, thanks!',
    content: ['This is how to investigate all groups', 'and results in the See Text section. 😎']
  }]
];

export default filteringGroups;
import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import ReactGA from 'react-ga';
import { GoogleOAuthProvider } from '@react-oauth/google';

import ErrorBoundary from './ErrorBoundary';
import Routes from 'routes';
import ProtectorProduction, { Loader } from 'routes/ProtectorProduction';
import './index.css';
import 'css/utility/color.css';
import 'css/utility/font.css';
import 'css/utility/space.css';
import 'css/utility/input.css';
import 'css/utility/other.css';
import 'css/utility/margin.css';
import 'css/utility/padding.css';
import 'css/utility/print.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

const CLIENT_ID: string = process.env.REACT_APP_CLIENT_ID? process.env.REACT_APP_CLIENT_ID: '';

const App = () => {
  useEffect(() => {
    ReactGA.initialize("G-Z0S9S9V3FV");
  }, []);
  return (
    <ErrorBoundary componentName="General">
      <Router>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick/>
        <GoogleOAuthProvider clientId={CLIENT_ID}>
          <Routes Protector={ProtectorProduction} Loader={Loader} />
        </GoogleOAuthProvider>
      </Router>
    </ErrorBoundary>
  );
};

export default App;

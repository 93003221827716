import type { LocalNotEditableWarning, LocalStorageFunctions } from './types';

const NOT_EDITABLE_WARNING: string = 'not-editable-warning';

const getLocalStorageNotEditableWarning = (fn: LocalStorageFunctions) => (): boolean => !!fn.readObject(NOT_EDITABLE_WARNING);
const setLocalStorageNotEditableWarning = (fn: LocalStorageFunctions) => () => fn.writeObject(NOT_EDITABLE_WARNING, true);

const notEditableWarning = (fn: LocalStorageFunctions): LocalNotEditableWarning => ({
  getLocalStorageNotEditableWarning: getLocalStorageNotEditableWarning(fn),
  setLocalStorageNotEditableWarning: setLocalStorageNotEditableWarning(fn)
});

export default notEditableWarning;

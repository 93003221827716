import type { LocalStorageFunctions, IntUserResponse, LocalUxFeaturesOrder } from './types';
import { DEFAULT_ITEMS } from 'constants/uxFeaturesOrder';

const UX_FEATURES_ORDER: string = 'ux-features-order';
const USER: string = 'tigim-user';

const getUser = (fn: LocalStorageFunctions) => (): IntUserResponse | undefined =>
  fn.readObject(USER);

const getLocalStorageUxFeaturesOrder = (fn: LocalStorageFunctions) => (): string[] => {
  const user = getUser(fn)();
  if (!user) return DEFAULT_ITEMS;
  const choice = fn.readObject(UX_FEATURES_ORDER);
  if (!choice?.[user.user.email]) return DEFAULT_ITEMS;
  return choice[user.user.email];
};

const setLocalStorageUxFeaturesOrder = (fn: LocalStorageFunctions) => (data: string[]) => {
  const user = getUser(fn)();
  if (!user) return undefined;
  let choice = fn.readObject(UX_FEATURES_ORDER);
  if (!choice) {
    choice = {};
  }
  choice[user.user.email] = data;
  fn.writeObject(UX_FEATURES_ORDER, choice);
};

const delLocalStorageUxFeaturesOrder = (fn: LocalStorageFunctions) => () => {
  const user = getUser(fn)();
  if (!user) return undefined;
  const choice = fn.readObject(UX_FEATURES_ORDER);
  if (!choice?.[user.user.email]) return undefined;
  delete choice[user.user.email];
  fn.writeObject(UX_FEATURES_ORDER, choice);
};

const uxFeaturesOrder = (fn: LocalStorageFunctions): LocalUxFeaturesOrder => ({
  getLocalStorageUxFeaturesOrder: getLocalStorageUxFeaturesOrder(fn),
  setLocalStorageUxFeaturesOrder: setLocalStorageUxFeaturesOrder(fn),
  delLocalStorageUxFeaturesOrder: delLocalStorageUxFeaturesOrder(fn)
});

export default uxFeaturesOrder;

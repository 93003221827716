const validateEmail = (email: string) => {
  const parts = email.split('@');
  if (parts.length !== 2) return false;
  const [name, domain] = parts;
  // eslint-disable-next-line
  const reName = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))$/;
  if (!reName.test(name)) return false;
  const reDomain = /^((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reDomain.test(domain);
};

export default validateEmail;

import type { LocalStorageFunctions, LocalWordCloudSearched } from './types';

const WORD_CLOUD_SEARCHED: string = 'tigim-word-cloud-searched';

const getObj = (fn: LocalStorageFunctions) => () => fn.readObject(WORD_CLOUD_SEARCHED);

const get = (fn: LocalStorageFunctions) => (id: string, defaultValue: boolean): boolean => {
  const obj = getObj(fn)();
  if (obj && obj[id] !== undefined) return obj[id];
  return defaultValue;
};

const set = (fn: LocalStorageFunctions) => (id: string, isOpen: boolean) => {
  const obj = getObj(fn)();
  if (!obj) return fn.writeObject(WORD_CLOUD_SEARCHED, { [id]: isOpen });
  obj[id] = isOpen;
  return fn.writeObject(WORD_CLOUD_SEARCHED, obj);
};

const wordCloudSearched = (fn: LocalStorageFunctions): LocalWordCloudSearched => ({
  get: get(fn),
  set: set(fn)
});

export default wordCloudSearched;

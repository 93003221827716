import React from 'react';

import { useMainContext } from 'routes/MainContextProvider';
import TutorialMessageBox from 'components/molecules/TutorialMessages/TutorialMessageBox';
import { TUTORIAL_SHARING } from 'constants/tutorials';

interface Props {
  step: number;
  box?: number;
  children?: React.ReactNode;
}

const TutorialMessageSharing = ({step, children, box = 0}: Props) => {
  const { tutorial } = useMainContext();
  if (tutorial !== TUTORIAL_SHARING) return null;
  return (
    <TutorialMessageBox step={step} box={box}>
      {children}
    </TutorialMessageBox>
  );
};

export default TutorialMessageSharing;

import React, { useState } from 'react';
import { useGoogleLogin, TokenResponse, CodeResponse } from '@react-oauth/google';
import {toastr} from 'react-redux-toastr';

import useFocus from 'hooks/useFocus';
import isOnLineGmail from 'functions/general/isOnLineGmail';
import validateEmail from 'functions/general/validateEmail';
import validatePassword from 'functions/general/validatePassword';
import tigimIcon from 'images/tigim.svg';
import feature1 from 'images/step2/modalSignUp/feature1.svg';
import feature2 from 'images/step2/modalSignUp/feature2.svg';
import feature3 from 'images/step2/modalSignUp/feature3.svg';
import feature4 from 'images/step2/modalSignUp/feature4.svg';
import passwordIcon from 'images/signUpLarge/password.svg';
import gmailIcon from 'images/signUpLarge/gmail.svg';
import InputPassword from 'components/atoms/InputPassword';
import Feature from './Feature';
import Modal from './Modal'
import {
  ModalBodySignUp,
  InputModal,
  SpanModal,
  FormDiv,
  OptionsDiv,
  LoginDiv,
  FeaturesDiv,
  ButtonSignUp,
  ButtonLogIn,
  OptionsPasswordDiv,
  OptionsGmailDiv
} from './elements';
import type { ModalSignUpProps } from './types';

const ModalSignUpLarge = ({onClose, onSend, onSendGmail}: ModalSignUpProps) => {
  const [optionPassword, setOptionPassword] = useState<boolean>(false);
  const [gmailError, setGmailError] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const [nameRef, setNameFocus] = useFocus();
  const [emailRef, setEmailFocus] = useFocus();
  const [passwordRef, setPasswordFocus] = useFocus();
  const [password2Ref, setPassword2Focus] = useFocus();

  const handleClose = () => {
    setOptionPassword(false);
    onClose();
  }
  const handleSend = () => {
    if (fullName.length < 3) return setNameFocus();
    if (!validateEmail(email)) return setEmailFocus();
    if (!validatePassword(password)) return setPasswordFocus();
    if (password !== password2) return setPassword2Focus();
    onSend({ email, password, first_name: fullName, last_name: '' });
    handleClose();
  };

  const onSuccess = (googleUser: TokenResponse | CodeResponse) => {
    if (isOnLineGmail(googleUser)) {
      onSendGmail(googleUser);
      onClose();
    }
  };

  const onError = (gmailErrorPicked: Pick<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => {
    if (gmailErrorPicked.error) {
      setGmailError(true);
      toastr.error('', gmailErrorPicked.error);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      handleSend();
    }
  };
  const signIn = useGoogleLogin({
    onSuccess,
    onError
  });
  const login = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    signIn();
  }
  return (
    <Modal onClose={handleClose}>
      <ModalBodySignUp onClick={(e: React.MouseEvent<HTMLElement>) => e.preventDefault()}>
        <button
          className="close"
          type="button"
          aria-label="Close"
          onClick={handleClose}>
          <span aria-hidden="true">×</span>
        </button>
        <img src={tigimIcon} alt="tigim" />
        <h5>Create an account</h5>
        <FeaturesDiv>
          <Feature icon={feature1} alt="Advanced analysis">
            <span>View your advanced analytics</span>
          </Feature>
          <Feature icon={feature3} alt="Curated recommendations">
            <span>Recommendations on your text</span>
          </Feature>
          <Feature icon={feature2} alt="Retain all your word">
            <span>Store and edit your work</span>
          </Feature>
          <Feature icon={feature4} alt="3 free credits">
            <>
              <span>10 free credits with your new account!</span>
              <span>Each credit gives you a free analysis</span>
            </>
          </Feature>
        </FeaturesDiv>
        {!optionPassword && (
          <OptionsDiv>
            <span>Sign up Options</span>
            <OptionsPasswordDiv onClick={() => setOptionPassword(true)}>
              <img src={passwordIcon} alt="email and password" />
              <span>Sign up with Email</span>
            </OptionsPasswordDiv>
            {!gmailError && (
              <OptionsGmailDiv onClick={login}>
                <img src={gmailIcon} alt="gmail" />
                <span>Sign up with Google</span>
              </OptionsGmailDiv>
            )}
          </OptionsDiv>
        )}
        {optionPassword && (
          <FormDiv>
            <InputModal
              ref={nameRef}
              id="full_name"
              name="full_name"
              placeholder="Name"
              className={fullName.length > 2? '': 'empty'}
              value={fullName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFullName(e.target.value)}/>
            <InputModal
              ref={emailRef}
              id="email"
              name="email"
              placeholder="Email"
              className={validateEmail(email)? '': 'empty'}
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}/>
            <InputPassword
              reference={passwordRef}
              id="password"
              placeholder="Create a password"
              value={password}
              onKeyPress={() => {
                // This is intentional
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}/>
            <InputPassword
              reference={password2Ref}
              id="password2"
              placeholder="Re-enter the password"
              value={password2}
              onKeyPress={onKeyPress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword2(e.target.value)}/>
            <SpanModal>
              <span>We'll also e-mail you the details.</span>
            </SpanModal>
            <ButtonSignUp
              type="button"
              aria-label="Sign up"
              onClick={handleSend}>
              SIGN UP
            </ButtonSignUp>
          </FormDiv>
        )}
        <LoginDiv>
          <span>Already have an account?</span>
          <ButtonLogIn>Login</ButtonLogIn>
        </LoginDiv>
      </ModalBodySignUp>
    </Modal>
  );
};

export default ModalSignUpLarge;

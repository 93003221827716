import React, { Component, ErrorInfo, ReactNode } from 'react';
import styled from 'styled-components';
import { toastr } from 'react-redux-toastr';
import axios from 'axios';

import apiHttp from 'services/http/apiHttp';
import promiseWrapper from 'functions/general/promiseWrapper';
import ErrorLayout from 'layouts/Error';
import errorIcon from 'images/error.svg';
import { DEFAULT } from 'colors';

const wrapper = promiseWrapper(toastr);
const api = apiHttp(axios, wrapper);

interface State {
  hasError: boolean;
  errorMessage: string;
}

interface Props {
  componentName: string;
  sendError?: (data: ErrorData) => Promise<any>;
  children: ReactNode;
}

export interface ErrorData {
  component: string;
  msg: string;
  url: string;
}

const contactUs = () => window.open('mailto:niamh@tigim.co');
const sendError = (data: ErrorData) => api.error.saveError(data);

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    errorMessage: ''
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, errorMessage: error.toString()};
  }

  public componentDidCatch(error: Error, _errorInfo: ErrorInfo) {
    this.setState({hasError: true, errorMessage: error.toString() });
    let func = sendError;
    if (this.props.sendError) {
      func = this.props.sendError;
    }
    console.log('ErrorBoundary => ', error.toString());
    func({
      url: window.location.href,
      msg: error.toString(),
      component: this.props.componentName
    });
  }

  public render() {
    if (!this.state.hasError) return this.props.children;
    return (
      <ErrorLayout page="Error">
        <Main>
          <img src={errorIcon} alt="Error page" />
          <div>
            <p>We are sorry, an error has occured!</p>
            <p className="try">{getMessage(this.state.errorMessage)}</p>
            <p>If the error continues, please contact us.</p>
            <button className="contactUs" type="button" aria-label="Contact Us" onClick={contactUs}>Contact Support</button>
          </div>
        </Main>
      </ErrorLayout>
    );
  }
}

export default ErrorBoundary;

const getMessage = (errorMessage: string) =>
  errorMessage.toString().includes('ChunkLoadError')? 'Please refresh the page using the keys: Ctrl + Shift + R' : 'Please try again';

export const Main = styled.main`
  display: grid;
  grid-template-columns: 32px 885px 1fr;
  > img {
    grid-column: 2;
    margin-top: 90px;
    margin-bottom: 50px;
    justify-self: center;
  }
  > div {
    grid-column: 2;
    position: relative;
    justify-self: center;
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  > div > p {
    margin-top: 5px;
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: ${DEFAULT};
  }
  > div > p.try {
    margin-bottom: 0px;
  }
  > div > button.contactUs {
    align-self: start;
    padding: 16px 42px;
    width: 202px;
    border-radius: 8px;
    border-width: 0px;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    background: linear-gradient(93.02deg, #7933FE -12.7%, #30D4DE 121.16%);
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
    color: white;
    margin-bottom: 50px;
  }
`;

import React, { useState } from 'react';

import { useMainContext } from 'routes/MainContextProvider';
import CookieBanner from './CookieBanner';

const CookieBannerDispatcher = () => {
  const { local } = useMainContext();
  const [isAccepted, setIsAccepted] = useState<boolean>(local.cookies.getLocalStorageCookies());

  const onAccept = () => {
    local.cookies.setLocalStorageCookies();
    setIsAccepted(true)
  }

  if (isAccepted) return null;
  return (<CookieBanner onAccept={onAccept} />);
};

export default CookieBannerDispatcher;

import type { HTTPFunctions } from '../types';
import type { NewProfileAudience, ProfileAudience } from 'pages/NewDocument/types';

const profiles = ({ get, post, put, del }: HTTPFunctions) => ({
  getProfiles: () => get('audience_profile/'),
  getProfileById: (id: number) => get(`audience_profile/${id}`),
  addProfile: (profile: NewProfileAudience) => post('audience_profile/', {...profile}),
  updateProfile: (profile: ProfileAudience) => put(`audience_profile/${profile.id}`, {...profile}),
  delProfile: (id: number) => del(`audience_profile/${id}`),
  getProfileUsersToShare: (id: number) => get(`audience_profile/share/${id}`),
  setProfileUsersToShare: (id: number, data: any) => put(`audience_profile/share/${id}`, data)
});

export default profiles;
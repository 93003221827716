const contact = (subject: string) => ({
  push: (url: string) => {
    window.open(url, '_blank');
  },
  email: (url: string) => {
    if (subject && subject.length > 0) {
      url = `${url}?subject=${subject}`;
    }
    window.open(url, '_blank');
  }
});

export default contact;

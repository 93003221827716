import { toastr } from 'react-redux-toastr';

import authHeader from './authHeader';
import auth from './auth';
import analysis from './analysis';
import payments from './payments';
import error from './error';
import api from './api';
import webChecker from './webChecker';
import adminAccounts from './adminAccounts';
import errorMessage from 'functions/general/errorMessage';
import getLocal from 'services/getLocal';
import { HTTPFunctions, ApiHTTP, GmailSignInData } from './types';

const catcher = (callback: any) => {
  try {
    return callback;
  } catch (e) {
    return errorMessage(toastr)(e);
  }
}

const hasNotValidCredentials = (message: string) =>
  ['Request failed with status code 401'].includes(message);

const checkCredentials = (prom: Promise<any>) =>
  prom.then((response: any) => {
    return Promise.resolve(response);
  }).catch((errorResponse: any) => {
    if (hasNotValidCredentials(errorResponse.message)) {
      const user = getLocal(localStorage).auth.getUser();
      if (user) {
        getLocal(localStorage).auth.delUser();
        window.location.replace('/');
      }
    }
    return Promise.reject(errorResponse);
  });

const getFunctions = (http: any, apiURL: string | undefined, wrapper: (prom: Promise<any>) => Promise<any>): HTTPFunctions => {
  const instance = http.create({ baseURL: `${apiURL}` });
  return {
    instance,
    wrapper,
    get: async (url: string) => wrapper(catcher(checkCredentials(instance.get(url, { headers: authHeader()})))),
    getPDF: async (url: string) => wrapper(catcher(checkCredentials(instance.get(url, {
      headers: { ...authHeader(),
        'Content-type': 'application/pdf',
      },
      responseType: 'blob'
    })))),
    post: async (url: string, data: any) => wrapper(catcher(checkCredentials(instance.post(url, data, { headers: authHeader()}).catch((e: React.MouseEvent<HTMLElement>) => Promise.reject(e))))),
    postGmail: async (url: string, data: GmailSignInData) => wrapper(catcher(checkCredentials(instance.post(
      url,
      data,
      { headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Google-OAuth2-Type': 'client'
    }})).catch((e: React.MouseEvent<HTMLElement>) => Promise.reject(e)))),
    postFile: async (url: string, data: any) => wrapper(catcher(checkCredentials(instance.post(
      url,
      data,
      { headers: {
        ...authHeader(),
        'Content-Type': 'multipart/form-data'
      }}
    )))),
    put: async (url: string, data: any) => wrapper(catcher(checkCredentials(instance.put(url, data, { headers: authHeader()})))),
    patch: async (url: string, data: any) => wrapper(catcher(checkCredentials(instance.patch(url, data, { headers: authHeader()})))),
    del: async (url: string) => wrapper(catcher(checkCredentials(instance.delete(url, { headers: authHeader()})))),
    delData: async (url: string, data: any) => wrapper(catcher(checkCredentials(instance.delete(url, {data, headers: authHeader()})))),
  }
}

const apiHttp = (http: any, wrapper: (prom: Promise<any>) => Promise<any>): ApiHTTP => ({
  auth: auth(getFunctions(http, process.env.REACT_APP_AUTH_HOST, wrapper)),
  analysis: analysis(getFunctions(http, process.env.REACT_APP_ANALYSIS_HOST, wrapper)),
  payments: payments(getFunctions(http, process.env.REACT_APP_PAYMENTS_HOST, wrapper)),
  error: error(getFunctions(http, process.env.REACT_APP_NOTIFICATIONS_HOST, wrapper)),
  api: api(getFunctions(http, process.env.REACT_APP_API_HOST, wrapper)),
  webChecker: webChecker(getFunctions(http, process.env.REACT_APP_WEB_CHECKER_HOST, wrapper)),
  admin: {
    accounts: adminAccounts(getFunctions(http, process.env.REACT_APP_ADMIN_HOST, wrapper))
  }
});

export default apiHttp;

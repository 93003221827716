import { Div, MainDiv } from './elements';
import FullscreenLoaderSVG from './FullscreenLoaderSVG';

type Props = {
  text: string;
  show: boolean;
}

const FullscreenLoaderSVGDispatcher = ({ text, show}: Props) => {
  if (!show) return null;
  return (
    <Div className="flex-col align-items-center justify-content-center color-purple9">
      <MainDiv>
        <FullscreenLoaderSVG text={text} show={show} />
      </MainDiv>
    </Div>
  );
}

export default FullscreenLoaderSVGDispatcher;

import type { ToastrEmitter } from 'react-redux-toastr';

import errorMessage from './errorMessage';

const promiseWrapper = (toastr: ToastrEmitter) => (prom: Promise<any>) =>
  prom.then((response: {data: any}) => {
    return Promise.resolve(response.data);
  }).catch((error: unknown) => {
    errorMessage(toastr)(error);
    return Promise.reject(error);
  });

export default promiseWrapper;

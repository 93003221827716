import React from 'react';

import { useMainContext } from 'routes/MainContextProvider';
import MenuDesktopAdmin from './MenuDesktopAdmin';
import MenuDesktop from './MenuDesktop';
import type { MenuDesktopDispatcherProps } from './types';
import type { IntUserResponse } from 'services/local/types';

const MenuDesktopDispatcher = ({ isOpen, setIsOpen, showAvailable }: MenuDesktopDispatcherProps) => {
  const { local, user, name, isLoggedIn } = useMainContext();
  const userName = isLoggedIn && user?.user? user.user.first_name: name;
  let userAuth: IntUserResponse | undefined = local.auth.getUser();
  if (userAuth === undefined) {
    userAuth = user;
  }

  if (isLoggedIn && local.auth.isAdmin()) {
    return (
      <MenuDesktopAdmin
        name={userName}
        user={userAuth}
        isOpen={isOpen}
        setIsOpen={setIsOpen} />
    );
  }
  return (
    <MenuDesktop
      showAvailable={showAvailable}
      name={userName}
      user={userAuth}
      isOpen={isOpen}
      setIsOpen={setIsOpen} />
  );
};

export default MenuDesktopDispatcher;

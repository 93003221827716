import React from 'react';

import { useMainContext } from 'routes/MainContextProvider';
import { open as setOpenContactLogin } from 'store/reducers/contactLogin';
import { MenuItemDiv } from './elements';
import { LeftMenuItemProps } from './types';

const LeftMenuItem = ({  name, url, active, icon, iconWhite, id, open, auth, leftMenuClick, highlight }: LeftMenuItemProps) => {
  const { history, nextStep, isLoggedIn, dispatch, local } = useMainContext();

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (auth && !isLoggedIn) {
      local.selectedDocuments.remove();
      local.compareDocument.delCompareDocument();
      return dispatch(setOpenContactLogin(''));
    }
    if (highlight) {
      nextStep();
      if (leftMenuClick) return leftMenuClick(url);
      return history(url);
    }
    if (leftMenuClick) {
      local.selectedDocuments.remove();
      local.compareDocument.delCompareDocument();
      return leftMenuClick(url);
    }
    local.selectedDocuments.remove();
    local.compareDocument.delCompareDocument();
    history(url);
  };
  return (
    <MenuItemDiv
      $highlight={highlight}
      $active={active}
      $open={open}
      onClick={onClick}
      aria-label={`Left menu ${name}`}
      id={id}>
      <div>
        <img src={active? iconWhite: icon} alt={name} />
      </div>
      {open && (<span>{name}</span>)}
    </MenuItemDiv>
  );
};

export default LeftMenuItem;

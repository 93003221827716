import React from 'react';
import { useMainContext } from 'routes/MainContextProvider';

import TUTORIALS from 'constants/tutorials';
import { Div } from './elements';

interface TutorialMessageProps {
  children: React.ReactNode;
  next: () => void;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
  doubleClick?: boolean;
}

const TutorialMessage = ({
  children,
  next,
  doubleClick = false,
  top="unset",
  bottom="unset",
  left="unset",
  right="unset"}: TutorialMessageProps) => {
  const { tutorialStep, tutorial, setTutorial } = useMainContext();
  const steps = getTutorialSteps(tutorial);

  const onNext = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    next();
  };

  const newProps: {onDoubleClick?: (e: React.MouseEvent<HTMLElement>) => void, onClick?: (e: React.MouseEvent<HTMLElement>) => void} = {}
  if (doubleClick) {
    newProps.onDoubleClick = onNext;
  } else {
    newProps.onClick = onNext;
  }

  return (
    <Div
      {...newProps}
      $top={top}
      $bottom={bottom}
      $left={left}
      $right={right}
      className="tm">
      <div>
        <button className="close" onClick={() => setTutorial('')}>x</button>
        {children}
        <div className='steps'>
          {tutorialStep + 1} / {steps}
        </div>
      </div>
    </Div>
  );
};

export default TutorialMessage;

const getTutorialSteps = (tutorial: string) => TUTORIALS[tutorial]?.length || 0;

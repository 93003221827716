import styled from 'styled-components';
import { Tooltip as ReactTooltip } from 'react-tooltip';

type DivProps = {
  $canOpen: boolean;
}

export const Div = styled.div<DivProps>`
  background-color: #F7F9FC;
  display: grid;
  grid-template-columns: ${({$canOpen}) => $canOpen? '245px 1fr 48px': '100px 1fr 48px'};
  > footer, > header, > main {
    grid-column: 2;
  }
`;

export const ReactTooltipDiv = styled(ReactTooltip)`
  z-index: 3;
  border-radius: 12px !important;
  font-weight: 700 !important;
  background: #222222EF !important;
`;

import { TutorialBox } from '../tutorialsTypes';

const addingText: TutorialBox[][] = [
  [{
    top: "200px",
    left: "240px",
    hasGrettings: true,
    content: ['Click here to start you first analysis!']
  }], [{
    top: "60px",
    right: "-200px",
    doNext: true,
    content: ['👈 You can copy in text of 50+', 'words']
  }, {
    top: "310px",
    right: "-210px",
    doNext: true,
    content: ['👈 Or add a Pdf document. It\'s', 'no problem if it contains images']
  }], [{
    top: "30px",
    right: "-210px",
    doNext: true,
    content: ['We’ve already prepared an', 'article for you']
  }], [{
    top: "0px",
    right: "100px",
    content: ['Click ‘Submit’']
  }], [{
    top: "70px",
    right: "-240px",
    doNext: true,
    content: ['Choose who is this for and the', 'purpose.', 'Each document purpose is', 'measured against different', 'targets based on our research']
  }]
];

export default addingText;

import React from 'react';

import { useMainContext } from 'routes/MainContextProvider';
import useOnLogout from 'hooks/useOnLogout';
import usersIcon from 'images/pages/admin/users.svg';
import shareIcon from 'images/pages/admin/share.svg';
import productIcon from 'images/pages/admin/product.svg';
import hamburger from 'images/hamburger.svg';
import downIcon from 'images/header/down.svg';
import userIcon from 'images/header/user.svg';
import logoutIcon from 'images/header/logout.svg';
import { Menu, Div, MenuDiv } from './elements';
import type { MenuDesktopAdminProps, MenuItemValue } from './types';

const MobMenuItem = (props: MenuItemValue) => (
  <button type="button" tabIndex={0} role="menuitem" onClick={props.onClick}>
    <img src={props.icon} alt={props.name} width={24} />
    <span>{props.name}</span>
  </button>
);

const MenuDesktopAdmin = ({
  user,
  name,
  isOpen,
  setIsOpen
}: MenuDesktopAdminProps) => {
  const { local, history } = useMainContext();

  const logout = useOnLogout();
  const goProducts = () => {
    history('/admin/products');
  };
  const goPlans = () => {
    history('/admin/plans');
  };
  const goUsersAministration = () => {
    history('/admin/users-administration');
  };
  const toggle = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };
  const singOut = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    logout();
    local.auth.delUser();
    setIsOpen(false);
  };
  const onGoTo = (func: () => void) => (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setIsOpen(false);
    func();
  }

  return (
    <Menu>
      <Div onClick={toggle}>
        <div className="name">
          <img src={userIcon} alt="user" className="user" data-tooltip-content={name} data-tooltip-id="tooltip-general" />
          <span>Hi! {user?.user?.first_name}</span>
          <img src={downIcon} alt="menu" className="down" />
        </div>
        <img src={hamburger} alt="menu" />
      </Div>
      {isOpen && (
        <MenuDiv tabIndex={-1} role="menu" aria-hidden="false">
          <MobMenuItem
            name="Users Administration"
            onClick={onGoTo(goUsersAministration)}
            icon={usersIcon} />
          <MobMenuItem
            name="Products"
            onClick={onGoTo(goProducts)}
            icon={productIcon} />
          <MobMenuItem
            name="Plans"
            onClick={onGoTo(goPlans)}
            icon={shareIcon} />
          <MobMenuItem
            name="Logout"
            onClick={singOut}
            icon={logoutIcon} />
        </MenuDiv>
      )}
    </Menu>
  );
};

export default MenuDesktopAdmin;

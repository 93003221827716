import type { LocalCardStates, LocalStorageFunctions } from './types';

const CARD_STATES: string = 'tigim-card-states';

const getObj = (fn: LocalStorageFunctions) => () => fn.readObject(CARD_STATES);

const get = (fn: LocalStorageFunctions) => (id: string, defaultValue: boolean = true): boolean => {
  const obj = getObj(fn)();
  if (obj && obj[id] !== undefined) return obj[id];
  return defaultValue;
};

const set = (fn: LocalStorageFunctions) => (id: string, isOpen: boolean) => {
  const obj = getObj(fn)();
  if (!obj) return fn.writeObject(CARD_STATES, { [id]: isOpen });
  obj[id] = isOpen;
  return fn.writeObject(CARD_STATES, obj);
};

const cardStates = (fn: LocalStorageFunctions): LocalCardStates => ({
  getLocalStorageCardState: get(fn),
  setLocalStorageCardState: set(fn)
});

export default cardStates;

import { TutorialBox } from '../tutorialsTypes';

const sharing: TutorialBox[][] = [
  [{
    top: "270px",
    left: "240px",
    content: ['Click here to go to your documents']
  }], [{
    bottom: "0px",
    left: "230px",
    content: ['Hover over document you', 'want to share to click on menu']
  }], [{
    bottom: "10px",
    left: "230px",
    content: ['Click to share with business partners']
  }], [{
    top: "50px",
    right: "-270px",
    content: ['Choose the people in your network', 'to share it with']
  }], [{
    top: "-10px",
    left: "50px",
    doNext: true,
    content: ['The documents with this icon', 'are shared to your network']
  }], [{
    top: "-20px",
    left: "350px",
    doNext: true,
    hasButtonNext: true,
    buttonNextText: 'Got it, thanks!',
    content: ['All documents that people share', 'with you will be stored here']
  }]
];

export default sharing;

import { TutorialBox } from '../tutorialsTypes';

const guideVideos: TutorialBox[][] = [
  [{
    top: "480px",
    left: "200px",
    doNext: true,
    content: ['click into “Watch Video” to learn', ' more about Tigim features']
  }]
];

export default guideVideos;

import { TutorialBox } from '../tutorialsTypes';

const organising: TutorialBox[][] = [
  [{
    top: "270px",
    left: "240px",
    content: ['Click here to go to your documents']
  }], [{
    top: "0px",
    left: "240px",
    content: ['Click on the title to name the', 'document']
  }], [{
    top: "0px",
    left: "420px",
    content: ['Update the document', 'name, and click ‘save’']
  }], [{
    top: "80px",
    left: "240px",
    content: ['Organise your folders under different', 'topics']
  }], [{
    top: "0px",
    left: "410px",
    content: ['Give a name to the folder', 'and click ‘Create Folder’']
  }], [{
    bottom: "-10px",
    left: "240px",
    content: ['Click on the menu dots', 'to see the options.']
  }], [{
    bottom: "-30px",
    left: "240px",
    content: ['Click to send to a folder']
  }], [{
    top: "30px",
    left: "350px",
    content: ['Choose the folder you want and it', 'will be stored there']
  }], [{
    top: "30px",
    left: "0px",
    doNext: true,
    hasButtonNext: true,
    buttonNextText: 'Got it, thanks!',
    content: ['Find your documents in your', 'folders']
  }]
];

export default organising;

import { useCallback, useState } from 'react';

const useToggle = (initialState: boolean = false): [boolean, (e: React.MouseEvent<HTMLOrSVGElement> | React.KeyboardEvent<HTMLOrSVGElement>) => void] => {
  const [state, setState] = useState<boolean>(initialState);

  const toggle = useCallback((e: React.MouseEvent<HTMLOrSVGElement> | React.KeyboardEvent<HTMLOrSVGElement>) => {
    e.stopPropagation();
    setState((val: boolean) => !val)
  }, []);

  return [state, toggle]
};

export default useToggle;

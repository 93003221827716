import type { LocalGA4Email, LocalStorageFunctions } from './types';

const GA4_EMAIL: string = 'ga4-email';

const getLocalStorageGA4Email = (fn: LocalStorageFunctions) => (): string => fn.readObject(GA4_EMAIL);
const setLocalStorageGA4Email = (fn: LocalStorageFunctions) => (email: string) => fn.writeObject(GA4_EMAIL, email);

const ga4Email = (fn: LocalStorageFunctions): LocalGA4Email => ({
  getLocalStorageGA4Email: getLocalStorageGA4Email(fn),
  setLocalStorageGA4Email: setLocalStorageGA4Email(fn)
});

export default ga4Email;

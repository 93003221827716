import type { LocalAnalysis, LocalStorageFunctions } from './types';

const LAST_FULL_REPORT_ID: string = 'tigim-full-report-id';

const getLastFullReportId = (fn: LocalStorageFunctions) => (): string => fn.readObject(LAST_FULL_REPORT_ID)?.id;
const setLastFullReportId = (fn: LocalStorageFunctions) => (id: string) => fn.writeObject(LAST_FULL_REPORT_ID, {id});
const delLastFullReportId = (fn: LocalStorageFunctions) => () => fn.removeObject(LAST_FULL_REPORT_ID);

const analysis = (fn: LocalStorageFunctions): LocalAnalysis => ({
  getLastFullReportId: getLastFullReportId(fn),
  setLastFullReportId: setLastFullReportId(fn),
  delLastFullReportId: delLastFullReportId(fn)
});

export default analysis;

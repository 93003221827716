import getLocal from 'services/getLocal';
import type { HTTPFunctions } from '../types';
import type { LogRequest } from 'pages/Analysis/types';
import type { AnalysisHandler, AnalysisHandlerFile } from 'pages/NewDocument/types';

const isLoggedIn = () => {
  return getLocal(localStorage).auth.getUser()?.access_token;
};

const analyze = (props: HTTPFunctions) => {
  const { get, post, postFile, put, del, getPDF } = props;
  return {
    getSummary: () => get('/summary/'),
    getMyWordBank: () => get('/my-word-bank/'),
    addWordToMyWordBank: (word: string, isJargon: boolean, isKeyTerm: boolean) => post('/my-word-bank/', { word, is_jargon: isJargon, is_key: isKeyTerm }),
    updateWordInMyWordBankJargon: (word: string, isJargon: boolean) => put(`/my-word-bank/${word}`, { is_jargon: isJargon }),
    updateWordInMyWordBankKeyTerm: (word: string, isKeyTerm: boolean) => put(`/my-word-bank/${word}`, { is_key: isKeyTerm }),
    addListToMyWordBank: (data: File) => {
      const formData = new FormData();
      formData.append('csv_file', data);
      formData.append('type', 'text/csv');
      return postFile('/my-word-bank/upload', formData);
    },
    deleteWordFromMyWordBank: (word: string) => del(`/my-word-bank/${word}`),
    documentAnalysisText: (data: AnalysisHandler) =>
      post(isLoggedIn() ? '/analyze/text' : '/analyze/public/text', data),
    documentAnalysisPDF: (data: AnalysisHandlerFile) =>
      postFile(isLoggedIn() ? '/analyze/file' : '/analyze/public/file', data),
    setReportLabel: (data: any) =>
      put(`/my-documents/${data.id}?label=${data.label}`, {}),
    deleteDocument: (id: string) => del(`/my-documents/${id}`),
    downloadReport: (id: string) => getPDF(`/my-documents/download/report/${id}`),
    downloadDocument: (id: string, typeDoc: string) =>
      getPDF(`/my-documents/download/document/${id}/${typeDoc}`),
    documentAnalysisById: (id: string, fingerprint: string) =>
      get(isLoggedIn()? `/my-documents/${id}`: `/my-documents/public/${id}/${fingerprint}`),
    myDocuments: (page: number, owner: string, folder: string | undefined) => {
      if (owner === 'shared-with-me') {
        return get(`/analyze/shared-with-me/preview/page/${page}/11`);
      }
      if (!folder) {
        return get(`/my-documents/preview/page/${page}/11`);
      }
      return get(`/folder/preview/page/${folder}/${page}/11`);
    },
    myDocumentsCount: () => get('/my-documents/count/'),
    addLog: (data: LogRequest) => post(`/analyze/log`, data),
    getLog: (id: string, record: string) => get(`/analyze/log/${record}/${id}`),
    reanalyze: (data: any) => post('/analyze/reanalize/original', data),
    reanalyzeByLogId: (id: string) => post(`/analyze/reanalize/log/${id}`, {}),
    getSuggestions: (data: any) => post('analyze/suggestions/', data),
    setSuggestionsFlag: (data: any) => put('analyze/suggestions/flag', data),
    documentsRebuild: (tigim: boolean) => post("/documents-rebuild/", { tigim }),
    getDocumentsRebuildStatus: () => get("/documents-rebuild/status"),
    delDocumentsRebuildStatus: () => del("/documents-rebuild/status")
  };
};

export default analyze;

import styled from 'styled-components';

export const Div = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  > input {
    height: 32px;
    padding: 4px 12px;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    overflow: visible;
    margin: 5px 0px 10px 0px;
    :focus {
      outline: 0;
      border-color: #35C801;
      box-shadow: 0px 0px 0px 2px rgba(53, 200, 1, 0.5);
    }
  }
  > button {
    border: none;
    background-color: transparent;
    position: absolute;
    right: 10px;
    top: 20px;
    > img {
      width: 18px;
    }
  }
`;

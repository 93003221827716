import type { LocalStorageFunctions } from './types';

const writeObject = (fn: any) => (name: string, data: any) => {
  fn.setItem(name, JSON.stringify(data));
};

const readObject = (fn: any) => (name: string) => {
  const data = fn.getItem(name);
  if (data) return JSON.parse(data);
  return undefined;
};

const removeObject = (fn: any) => (name: string) => {
  fn.removeItem(name);
};

const localStorageFunctions = (fn: any): LocalStorageFunctions => ({
  writeObject: writeObject(fn),
  readObject: readObject(fn),
  removeObject: removeObject(fn)
});

export default localStorageFunctions;

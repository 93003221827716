import styled from 'styled-components';

import { ModalDiv } from 'components/atoms/Modal/elements';
import { DEFAULT } from 'colors';

export const ModalBody = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 27px 18px;
  > h5 {
    align-self: center;
    margin-top: -25px;
    margin-bottom: 15px;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: ${DEFAULT};
  }
  > div {
    display: flex;
    flex-direction: row;
    align-self: center;
  }
  > div > div {
    display: flex;
    flex-direction: column;
  }
  > div > div > button {
    border: 0;
    width: 170px;
    height: 170px;
    display: flex;
    justify-content: center;
    background-color: #F5F8FC;
    border-radius: 12px;
    margin-left: 16px;
    margin-right: 16px;
    cursor: pointer;
  }
  > div > div > button:hover {
    transform: scale(1.05);
  }
  > div > div > button > img {
    align-self: center;
    cursor: pointer;
  }
  > div > div > span {
    margin-top: 15px;
    align-self: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: ${DEFAULT};
  }
  button.close {
    padding: 16px 16px;
    margin: -16px -16px -16px auto;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    float: right;
    font-size: 21px;
    font-weight: 600;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
    -webkit-appearance: button;
    text-transform: none;
    overflow: visible;
  }
  button.close > span {
    cursor: pointer;
  }
`;

export const ModalBodyContactUs = styled(ModalDiv)`
  max-width: 530px;
  > div {
    border-radius: 6px;
    height: 303px;
  }
`;

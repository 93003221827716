import React from 'react';
import styled from 'styled-components';

import {
  MainDiv,
  BackDropDiv,
  ModalContent,
  ModalDiv
} from 'components/atoms/Modal/elements';

interface ModalProps {
  children: string | React.ReactNode;
  onClose(): void;
}

export const ModalMd = styled(ModalDiv)`
  max-width: 409px;
  > div {
    height: 645px;
  }
`;

const Modal = ({ children, onClose }: ModalProps) => (
  <MainDiv>
    <ModalMd>
      <ModalContent>
        {children}
      </ModalContent>
    </ModalMd>
    <BackDropDiv onClick={onClose}/>
  </MainDiv>
);

export default Modal;

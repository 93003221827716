import { TutorialBox } from '../tutorialsTypes';

const bulkActions: TutorialBox[][] = [
  [{
    top: "270px",
    left: "240px",
    content: ['Click here to go to your documents']
  }], [{
    top: "40px",
    left: "-250px",
    content: ['Hover over document to see box']
  }], [{
    top: "0px",
    left: "-290px",
    content: ['Click on the box for a new menu bar', 'to appear and be able to select more', 'documents to share, move or delete']
  }], [{
    top: "0px",
    left: "0px",
    content: ['Add more documents to', 'the ‘bulk action’ wanted']
  }, {
    top: "60px",
    right: "0px",
    content: ['Do click on (share, move or delete)']
  }]
];
    
export default bulkActions;

import { createSlice } from '@reduxjs/toolkit'

import getLocal from 'services/getLocal';
import type { IntUserResponse } from 'services/local/types';

const getUser = () => {
  if (typeof localStorage !== 'undefined') return getLocal(localStorage).auth.getUser();
  return undefined;
};

const user = getUser();

type State = {
  isLoggedIn: boolean;
  pendingCredits: boolean;
  user: IntUserResponse | undefined;
}

type Action = {
  payload: IntUserResponse;
}

const initialState: State = user
  ? { isLoggedIn: true, pendingCredits: false, user }
  : { isLoggedIn: false, pendingCredits: false, user: undefined };

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state: State, action: Action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    register: (state: State, action: Action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },
    update: (state: State) => {
      state.user = getLocal(localStorage).auth.getUser()
    },
    logout: (state: State) => {
      getLocal(localStorage).analysis.delLastFullReportId();
      state.isLoggedIn = false;
      state.user = undefined;
    },
    pendingCredits: (state: State) => {
      state.pendingCredits = true;
    },
    setCredits: (state: State, action: Action) => {
      state.pendingCredits = false;
      state.user = action.payload;
    }
  },
});

export const { login, register, update, logout, pendingCredits, setCredits } = slice.actions;

export default slice.reducer;

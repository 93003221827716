import type { LocalStorageFunctions, IntUserResponse, LocalSelectedDocuments } from './types';
import type { IntDocumentRow } from 'pages/MyDocuments/types';

const SELECTED_DOCUMENTS: string = 'tigim-selected-documents';
const USER: string = 'tigim-user';

const getUser = (fn: LocalStorageFunctions) => (): IntUserResponse | undefined =>
  fn.readObject(USER);

const get = (fn: LocalStorageFunctions) => (): IntDocumentRow[] => {
  const user = getUser(fn)();
  if (!user) return [];
  const items = fn.readObject(SELECTED_DOCUMENTS);
  if (!items) return [];
  return items;
};

const set = (fn: LocalStorageFunctions) => (data: IntDocumentRow): void => {
  const user = getUser(fn)();
  if (!user) return undefined;
  let items = fn.readObject(SELECTED_DOCUMENTS);
  if (!items) {
    items = [];
  }
  const fimd = items.find((item: IntDocumentRow) => item.id === data.id);
  if (fimd) {
    if (data.selected === 0) return;
    items = items.filter((item: IntDocumentRow) => item.id !== data.id);
  } else {
    items.push(data);
  }
  fn.writeObject(SELECTED_DOCUMENTS, items);
};

const remove = (fn: LocalStorageFunctions) => (): void => {
  const user = getUser(fn)();
  if (!user) return;
  fn.writeObject(SELECTED_DOCUMENTS, []);
};

const selectedDocuments = (fn: LocalStorageFunctions): LocalSelectedDocuments => ({
  get: get(fn),
  set: set(fn),
  remove: remove(fn)
});

export default selectedDocuments;

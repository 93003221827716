import { AnalyzedDocument } from 'pages/WebCheckerMultiple/types';
import type { LocalStorageFunctions, LocalWebCheckerDocuments } from './types';

const WEB_CHECKER_DOCS: string = 'web-checker-docs';

const getLocalStorageWebCheckerDocuments = (fn: LocalStorageFunctions) => (url: string): AnalyzedDocument | null => {
  const choice = fn.readObject(WEB_CHECKER_DOCS);
  if (!choice?.[url]) return null;
  return choice[url];
};

const setLocalStorageWebCheckerDocuments = (fn: LocalStorageFunctions) => (url: string, doc: AnalyzedDocument) => {
  let choice = fn.readObject(WEB_CHECKER_DOCS);
  if (!choice) {
    choice = {};
  }
  choice[url] = doc;
  fn.writeObject(WEB_CHECKER_DOCS, choice);
};

const delLocalStorageWebCheckerDocuments = (fn: LocalStorageFunctions) => (url: string) => {
  const choice = fn.readObject(WEB_CHECKER_DOCS);
  if (!choice?.[url]) return;
  delete choice[url];
  fn.writeObject(WEB_CHECKER_DOCS, choice);
};

const webCheckerDocuments = (fn: LocalStorageFunctions): LocalWebCheckerDocuments => ({
  getLocalStorageWebCheckerDocuments: getLocalStorageWebCheckerDocuments(fn),
  setLocalStorageWebCheckerDocuments: setLocalStorageWebCheckerDocuments(fn),
  delLocalStorageWebCheckerDocuments: delLocalStorageWebCheckerDocuments(fn)
});

export default webCheckerDocuments;

import React from 'react';
import { Div, Span, Link, Button } from './elements';

export interface CookieBannerProps {
  onAccept(): void;
}

const CookieBanner = ({ onAccept }: CookieBannerProps) => (
  <Div>
    <Span>
      Tigim uses cookies to guarantee users the employment of its site features, offering a better experience. By continuing to browse the site you're agreeing to our use of cookies.
      <Link href="/cookie-policy" target="_blank" rel="noreferrer">
        More information on our use of cookies
      </Link>
    </Span>
    <Button onClick={onAccept}>Close</Button>
  </Div>
);

export default CookieBanner;

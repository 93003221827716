import React from 'react';

import ErrorThrower from 'ErrorThrower';
import { useMainContext } from 'routes/MainContextProvider';
import { open as setOpenContactUs } from 'store/reducers/contactUs';
import { ContactUsProps } from './types';
import { ContactUsDiv } from './elements';
import { DEFAULT } from 'colors';

const ContactUs = ({ active }: ContactUsProps) => {
  const { dispatch } = useMainContext();

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    dispatch(setOpenContactUs(''));
  };
  return (
    <ErrorThrower componentName="ContactUs">
      <ContactUsDiv onClick={onClick}>
      <svg width="54" height="54" viewBox="0 0 60 54" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_dd_3865_3675)">
          <rect x="6" width="54" height="54" rx="12" fill="white"/>
          <rect opacity="0.6" x="6" width="54" height="54" rx="12" fill="url(#paint0_linear_3865_3675)"/>
          <mask id="mask0_3865_3675" maskUnits="userSpaceOnUse" x="6" y="0" width="54" height="54">
          <rect x="6" width="54" height="54" rx="12" fill="white"/>
          </mask>
          <g filter="url(#filter1_dd_3865_3675)">
            <rect x="17" y="11.4788" width="32" height="30.9577" rx="8" fill="white"/>
          </g>
          <path d="M30 20.1855C29.4477 20.1855 29 20.6187 29 21.153C29 21.6873 29.4477 22.1204 30 22.1204H36C36.5523 22.1204 37 21.6873 37 21.153C37 20.6187 36.5523 20.1855 36 20.1855H30Z" fill="white"/>
          <path d="M27 24.0553C27 23.521 27.4477 23.0879 28 23.0879H38C38.5523 23.0879 39 23.521 39 24.0553C39 24.5896 38.5523 25.0227 38 25.0227H28C27.4477 25.0227 27 24.5896 27 24.0553Z" fill="white"/>
          <path d="M25 27.9248C25 26.8563 25.8954 25.99 27 25.99H39C40.1046 25.99 41 26.8563 41 27.9248V31.7945C41 32.8632 40.1046 33.7294 39 33.7294H27C25.8954 33.7294 25 32.8632 25 31.7945V27.9248Z" fill="white"/>
          <path opacity="0.6" d="M39.4 24L33 27.75L26.6 24V22.5L33 26.25L39.4 22.5M39.4 21H26.6C25.712 21 25 21.6675 25 22.5V31.5C25 31.8978 25.1686 32.2794 25.4686 32.5607C25.7687 32.842 26.1757 33 26.6 33H39.4C39.8243 33 40.2313 32.842 40.5314 32.5607C40.8314 32.2794 41 31.8978 41 31.5V22.5C41 21.6675 40.28 21 39.4 21Z" fill={DEFAULT} />
        </g>
        <defs>
          <filter id="filter0_dd_3865_3675" x="0.679918" y="0" width="64.6402" height="65.9702" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="2.76726"/>
            <feGaussianBlur stdDeviation="1.1069"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0196802 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3865_3675"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="6.6501"/>
            <feGaussianBlur stdDeviation="2.66004"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.0282725 0"/>
            <feBlend mode="normal" in2="effect1_dropShadow_3865_3675" result="effect2_dropShadow_3865_3675"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3865_3675" result="shape"/>
          </filter>
          <filter id="filter1_dd_3865_3675" x="12" y="10.4788" width="42" height="40.9578" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect1_dropShadow_3865_3675"/>
            <feOffset dy="2"/>
            <feGaussianBlur stdDeviation="2"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3865_3675"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect2_dropShadow_3865_3675"/>
            <feOffset dy="4"/>
            <feGaussianBlur stdDeviation="3"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"/>
            <feBlend mode="normal" in2="effect1_dropShadow_3865_3675" result="effect2_dropShadow_3865_3675"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_3865_3675" result="shape"/>
          </filter>
          <linearGradient id="paint0_linear_3865_3675" x1="-21" y1="27" x2="33" y2="81" gradientUnits="userSpaceOnUse">
            <stop stopColor="#7933FE"/>
            <stop offset="1" stopColor="#30D4DE"/>
          </linearGradient>
          </defs>
        </svg>
        {active && (<span>Contact Us</span>)}
      </ContactUsDiv>
    </ErrorThrower>
  );
};

export default ContactUs;

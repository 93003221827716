import { createSlice } from '@reduxjs/toolkit';

import type { AnalysisResponse } from  'pages/NewDocument/types';
import type { IntCompleteReport } from  'pages/Analysis/types';

interface State {
  analysisReport: AnalysisResponse | undefined;
  fullReport: IntCompleteReport | undefined;
  sendEmail: boolean;
}

interface SendEmailAction {
  payload: boolean;
}

interface AnalysisReportAction {
  payload: AnalysisResponse | undefined;
}

interface FullReportAction {
  payload: IntCompleteReport | undefined;
}

const initialState: State = {
  analysisReport: undefined,
  fullReport: undefined,
  sendEmail: true
};

export const slice = createSlice({
  name: 'analysisReport',
  initialState,
  reducers: {
    sendEmail: (state: State, action: SendEmailAction) => {
      state.sendEmail = action.payload;
    },
    setAnalysisReport: (state: State, action: AnalysisReportAction) => {
      state.analysisReport = action.payload;
    },
    setFullReport: (state: State, action: FullReportAction) => {
      state.fullReport = action.payload;
    }
  },
});

export const { sendEmail, setAnalysisReport, setFullReport } = slice.actions;

export default slice.reducer;

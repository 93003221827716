import type { AccountEdit, AccountLicencedEdit } from 'pages/Admin/UsersAdministration/types';
import { HTTPFunctions } from './types';

const adminAccounts = ({ get, post, put, del, getPDF }: HTTPFunctions) => ({
  addAccount: (data: AccountEdit) => post("/users/", data),
  addAccountLicenced: (data: AccountLicencedEdit) => post("/users/licence/", data),
  getheaders: () => get("/users/info/accounts"),
  listAccounts: () => get("/users/info"),
  getSubscriptions: () => get("/subscriptions"),
  getSubscriptionById: (id: string) => get(`/subscriptions/${id}`),
  resetCreditsAll: () => put('/credits/reset/all', {}),
  resetCreditsUpdateAll: (credits: number) => put(`/credits/all?credits_to=${credits}`, {}),
  resetCreditsSubscriptionById: (subscriptionId: string) => put(`/credits/reset/subscription/${subscriptionId}`, {}),
  resetCreditsUpdateById: (subscriptionId: string, credits: number) => put(`/credits/${subscriptionId}?credits_to=${credits}`, {}),
  resetCreditsLicenceById: (licenceId: number) => put(`/credits/reset/licence/${licenceId}`, {}),
  resetCreditsUpdateLicenceById: (licenceId: string, credits: number) => put(`/credits/licence/${licenceId}?credits_to=${credits}`, {}),
  getAccountById: (id: string) => get(`/accounts/${id}`),
  delAccountById: (id: string) => del(`/accounts/${id}`),
  delUserById: (id: number) => del(`/users/${id}`),
  downloadUserInsight: () => get(`/users/insights`),
  downloadUserInsightXLSX: () => getPDF(`/users/insights/xlsx`),
  updateAccount: (id: number, data: AccountEdit) => put(`/users/user_information/${id}`, data),
  listAllTags: () => get("/tags/"),
  createTag: (user_id: number, name: string) => post("/users/tags/", { user_id, name }),
  addUserTag: (user_id: number, tag_id: number) => put("/users/tags/", { user_id, tag_id }),
  listUserTags: (id: number) => get(`/users/tags/${id}`),
  delUserTagById: (id: number, user_id: number) => del(`/users/tags/${user_id}/${id}`),
  createNote: (user_id: number, note: string) => post("/users/notes/", { user_id, note }),
  listNotes: (user_id: number) => get(`/users/notes/${user_id}`),
  updateNote: (id: number, note: string) => put(`/users/notes/${id}`, { note }),
  delNoteById: (id: number) => del(`/users/notes/${id}`),
  addCredits: (user_id: number, credits: number, note: string) =>
    put(`/users/credits/${user_id}`, { credits, note })
});

export default adminAccounts;

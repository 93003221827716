import type { HTTPFunctions } from '../types';

const folder = (props: HTTPFunctions) => {
  const { get, post, put, del } = props;
  return {
    getFolder: (id: string) => get(`/folder/${id}`),
    getFolders: () => get('/folder/'),
    addFolder: (name: string) => post('/folder/', { name }),
    deleteFolder: (id: number) => del(`/folder/${id}`),
    setFolderNameById: (id: number, name: string) => put(`folder/${id}`, { name }),
    addDocumentToFolder: (documentId: string, folderId: number) =>
      post(`/folder/document/${folderId}/${documentId}`, {}),
  };
};

export default folder;

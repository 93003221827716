import React from 'react';

import { useMainContext } from 'routes/MainContextProvider';
import TutorialMessage from 'components/atoms/TutorialMessage';
import { ButtonTutorial } from './elements';

interface TutorialMessageBoxProps {
  step: number;
  box: number;
  children?: React.ReactNode;
}

const TutorialMessageBox = ({step, children, box}: TutorialMessageBoxProps) => {
  const { tutorialStep, tutorialBoxes, nextStep, user} = useMainContext();
  if (tutorialStep !== step) return null;
  const boxData = tutorialBoxes?.[box];
  if (!boxData) return null;
  const {
    content,
    doNext=false,
    hasGrettings=false,
    hasButtonNext=false,
    buttonNextText="",
    ...params
  } = boxData;
  const next = () => {
    if (doNext) nextStep();
  };
  return (
    <TutorialMessage next={next} {...params}>
      {hasGrettings && (
        <p>Hi {user?.user?.first_name}! 👋</p>
      )}
      {content?.map((message: string) => (
        <p key={message}>{message}</p>
      ))}
      {children}
      {hasButtonNext && (
        <ButtonTutorial onClick={nextStep}>{buttonNextText}</ButtonTutorial>
      )}
    </TutorialMessage>
  );
};

export default TutorialMessageBox;

import type { ToastrEmitter } from "react-redux-toastr";

const errorMessageWithDetail = (toastr: ToastrEmitter, detail: any): void => {
  if (detail === 'Null values not allowed') return toastr.error('This element can\'t be deleted', '');
  if (Array.isArray(detail) && detail.length > 0 && detail[0].msg) {
    return toastr.error(detail[0].msg, '');
  }
  return toastr.error(detail, '');
};

const errorMessage = (toastr: ToastrEmitter) => (error: any): void => {
  if (error?.response?.data?.detail) {
    return errorMessageWithDetail(toastr, error.response.data.detail);
  }
  let message = error?.response?.data?.message || error?.message || error?.toString();
  if (message === undefined) {
    message = 'Error unexpected';
  }
  toastr.error(message, '');
};

export default errorMessage;

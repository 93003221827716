import React, { useRef, useState } from 'react';

const getNumberFromPx = (px: string) => {
  const number = parseInt(px.replace('px', ''));
  return isNaN(number)? 0 : number;
};

const useMovable = (movable: boolean) => {
  const [offset, setOffset] = useState<[number, number]>([0, 0]);
  const [isDown, setIsDown] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  const onMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!movable) return;
    const div = ref.current;
    if (!div) return;
    setIsDown(true);
    setOffset([e.clientX, e.clientY]);
  };
  
  const onMouseUp = () => {
    if (!movable) return;
    setIsDown(false);
  };
  
  const onMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!movable || !isDown) return;
    const div = ref.current;
    if (!div) return;
    const difX = e.clientX - offset[0];
    const difY = e.clientY - offset[1];
    if (!div.style.left && !div.style.top) {
      div.style.left = difX + 'px';
      div.style.top = difY + 'px';
    } else {
      div.style.left = (getNumberFromPx(div.style.left) + difX) + 'px';
      div.style.top = (getNumberFromPx(div.style.top) + difY) + 'px';
    }
    setOffset([e.clientX, e.clientY]);
  };

  const onMouseLeave = (_e: React.MouseEvent<HTMLDivElement>) => {
    if (!movable || !isDown) return;
    setIsDown(false);
    setOffset([0, 0]);
  };

  return {
    ref,
    onMouseLeave,
    onMouseMove,
    onMouseDown,
    onMouseUp
  }
};

export default useMovable;

import type { LocalStorageFunctions, IntUserResponse, LocalProductSaved } from './types';
import type { AppropriateTextSaved } from 'pages/ECommerceCreate/types';

const PRODUCT_SAVED: string = 'product-saved';
const USER: string = 'tigim-user';

const getUser = (fn: LocalStorageFunctions) => (): IntUserResponse | undefined =>
  fn.readObject(USER);

const getLocalStorageProductSaved = (fn: LocalStorageFunctions) => (): AppropriateTextSaved[] => {
  const user = getUser(fn)();
  if (!user) return [];
  const choice = fn.readObject(PRODUCT_SAVED);
  if (!choice?.[user.user.email]) return [];
  return choice[user.user.email];
};

const setLocalStorageProductSaved = (fn: LocalStorageFunctions) => (data: AppropriateTextSaved[]) => {
  const user = getUser(fn)();
  if (!user) return;
  let choice = fn.readObject(PRODUCT_SAVED);
  if (!choice) {
    choice = {};
  }
  choice[user.user.email] = data;
  fn.writeObject(PRODUCT_SAVED, choice);
};

const delLocalStorageProductSaved = (fn: LocalStorageFunctions) => () => {
  const user = getUser(fn)();
  if (!user) return;
  const choice = fn.readObject(PRODUCT_SAVED);
  if (!choice?.[user.user.email]) return;
  delete choice[user.user.email];
  fn.writeObject(PRODUCT_SAVED, choice);
};

const productSaved = (fn: LocalStorageFunctions): LocalProductSaved => ({
  getLocalStorageProductSaved: getLocalStorageProductSaved(fn),
  setLocalStorageProductSaved: setLocalStorageProductSaved(fn),
  delLocalStorageProductSaved: delLocalStorageProductSaved(fn)
});

export default productSaved;

import { useRef, useCallback } from "react";
import { useSelector, useDispatch } from 'react-redux';
import type { ToastrEmitter } from "react-redux-toastr";

import { open as setOpenLoading, close as setCloseLoading } from 'store/reducers/loading';

interface Ref {
  current?: NodeJS.Timeout | null;
  id?: number | null;
}

const isObject = (element: any) => typeof element === 'object' && element !== null;

const useLoading = (toastr: ToastrEmitter) => {
  const closer: Ref = useRef();
  const dispatch = useDispatch();
  const isLoading = useSelector((state: any) => state.loading.isLoading)

  const closeLoading = useCallback(() => {
    closer?.current && clearTimeout(closer.current);
    return dispatch(setCloseLoading())
  }, [dispatch, closer]);

  const errorLoading = useCallback((message: any) => {
    closer?.current && clearTimeout(closer.current);
    let messageError = message;
    if (isObject(message)) {
      messageError = message?.detail;
      if (!messageError) {
        messageError = '';
      }
    }
    toastr.error('', messageError);
    return dispatch(setCloseLoading());
  }, [dispatch, closer, toastr]);

  const openLoading = useCallback((message: string) => {
    closer.current = setTimeout(() => {
      if (isLoading) {
        toastr.warning('', 'There is one problem, please reload the page');
      }
      closeLoading();
    }, 360000);
    return dispatch(setOpenLoading(message))
  }, [dispatch, closeLoading, isLoading, toastr]);

  return { openLoading, closeLoading, errorLoading };
}

export default useLoading;

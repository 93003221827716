import { createSlice } from '@reduxjs/toolkit';

interface State {
  signIn: boolean;
}

const initialState: State = {
  signIn: false
};

export const slice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    open: (state: State) => {
      state.signIn = true;
    },
    close: (state: State) => {
      state.signIn = false;
    }
  }
});

export const { open, close } = slice.actions;

export default slice.reducer;
